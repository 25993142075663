import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { RemoveScrollBar } from 'react-remove-scroll-bar';

interface ConfirmModalProps {
  toggleModal: Function;
  show: boolean;
  warningMessage: string;
  confirmMessage: string;
  handleConfirm: Function;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  toggleModal,
  show,
  warningMessage,
  confirmMessage,
  handleConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Transition appear show={show} as={Fragment}>
      <div
        className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-75"
        onClick={() => toggleModal(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <RemoveScrollBar />
              <p className="text-lg font-semibold leading-6 text-red-500">
                {t('common.warning')}
              </p>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{warningMessage}</p>
              </div>

              <div className="mt-4 flex justify-between pt-8">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() => toggleModal(false)}
                >
                  {t('common.cancel')}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() => handleConfirm()}
                >
                  {confirmMessage}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default ConfirmModal;

import { ReactiveVar } from '@apollo/client/core';
import { RansackSort } from 'hooks/useRansackSort';
import { isEqual } from 'lodash';

export interface WrappedSort {
  identifier: unknown;
  sort: RansackSort;
}

export function usePreservedSort(
  preservedSort: ReactiveVar<WrappedSort[]>,
  identifier: unknown
) {
  const _preservedSort = preservedSort();
  const handleSortingChange = (sort: RansackSort) => {
    const wrappedSort = {
      identifier,
      sort,
    };

    const existingSort = _preservedSort.find((x) =>
      isEqual(x.identifier, identifier)
    );

    if (existingSort) {
      existingSort.sort = wrappedSort.sort;
    } else {
      preservedSort([..._preservedSort, wrappedSort]);
    }
  };

  const activeSort = _preservedSort.find((x) =>
    isEqual(x.identifier, identifier)
  )?.sort;

  return {
    handleSortingChange,
    activeSort,
  };
}

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import icon from 'assets/img/pplus-icon.png';
import { LoginForm } from './LoginForm';

const Login: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('access.login.pageTitle')} | {t('common.siteTitle')}
        </title>
      </Helmet>
      <div className="flex flex-col h-full px-3">
        <div className="flex">
          <img
            alt={t('common.purchasePlusIconAlt')}
            className="w-12"
            src={icon}
          />
        </div>
        <LoginForm />
      </div>
    </>
  );
};

export default Login;

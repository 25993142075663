import { useSession } from 'hooks/useSession';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'modules/common/components/DefaultLayout';
import Card from 'modules/common/components/Card';
import { sortProperties } from 'hooks/useRansackSort';
import useStockCountDraft from 'hooks/useStockCountDraft';
import StockLocationsListMini from 'modules/inventory/components/StockLocationsListMini';
import DraftStockCountList from 'modules/inventory/components/DraftStockCountList';

export default function Dashboard() {
  const { organisation, holder } = useSession();
  const { t } = useTranslation();
  const defaultSort = sortProperties(['name']);
  const {
    draftStockCountLength,
    getTopNStockCountDrafts,
  } = useStockCountDraft();

  const firstFiveDrafts = getTopNStockCountDrafts(5);

  const orgName = organisation.name;
  const mailto = t('purchasing.dashboard.supportEmailAddress');

  if (Object.keys(organisation).length === 0)
    return <p>{t('common.loading')}</p>;

  return (
    <>
      <Helmet>
        <title>
          {t('purchasing.dashboard.pageTitle')} | {t('common.siteTitle')}
        </title>
      </Helmet>
      <DefaultLayout title={organisation.name}>
        {organisation.isInventoryEnabled ? (
          <>
            {holder?.id && (
              <Card>
                <div className="flex justify-between items-baseline mb-6">
                  <div>
                    <h2 className="text-xl font-bold">
                      {t('purchasing.dashboard.stockLocations.header')}
                    </h2>
                  </div>
                  <Link
                    to="inventory/stock_locations"
                    className="font-bold text-blue-600"
                  >
                    {t('common.viewAll')}
                  </Link>
                </div>
                <StockLocationsListMini
                  first={5}
                  sort={defaultSort[0]}
                  holderId={holder?.id}
                />
              </Card>
            )}
          </>
        ) : (
          <div className="font-blue text-center text-lg ">
            <Trans
              i18nKey="purchasing.dashboard.inventoryNotEnabled"
              values={{ orgName, mailto }}
              tOptions={{ interpolation: { escapeValue: false } }}
              components={[
                <a href={`mailto:${mailto}`} className="text-blue">
                  {mailto}
                </a>,
              ]}
            />
          </div>
        )}
        <Card>
          <div className="flex justify-between items-baseline mb-6">
            <h2 className="text-xl font-bold">
              {t('inventory.drafts.unsavedStockCounts')}
            </h2>
            {draftStockCountLength > 0 && (
              <Link to="/draft_stock_takes" className="font-bold text-blue-600">
                {t('common.viewAll')}
              </Link>
            )}
          </div>
          {draftStockCountLength > 0 ? (
            <DraftStockCountList drafts={firstFiveDrafts} />
          ) : (
            <div className="justify-center flex">
              {t('inventory.drafts.noDraftAvailable')}
            </div>
          )}
        </Card>
      </DefaultLayout>
    </>
  );
}

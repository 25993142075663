import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNetworkState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { toast, TypeOptions } from 'react-toastify';
import DefaultLayout from 'modules/common/components/DefaultLayout';
import DraftStockCountList from 'modules/inventory/components/DraftStockCountList';
import useStockCountDraft, { DraftStockTake } from 'hooks/useStockCountDraft';
import Card from 'modules/common/components/Card';
import Button from 'modules/common/components/Button';
import SyncError from 'modules/common/components/SyncError';
import { UPDATE_STOCK_COUNT_MUTATION } from '../../components/StockCount';

export default function DraftStockTakesPage() {
  const { getDraftStockTakes, deleteDraftStockTake } = useStockCountDraft();
  const { t } = useTranslation();
  const { online } = useNetworkState();
  const [showUnSaved, setShowUnSaved] = useState(false);
  const [stockTakesDraft, setStockTakesDraft] = useState<
    DraftStockTake[] | undefined
  >();

  const syncAllDraftStockTake = async () => {
    let successStockCountIds: Array<number> = [];
    let failedStockCountIds: Array<number> = [];

    // Prepare mutations
    const responses = getDraftStockTakes().map((stockTakeItem) => {
      return {
        response: stockTakeItem.stockCounts?.map((item) => {
          return updateQuantity({
            variables: {
              id: item.stockCountId,
              quantity: item.quantity,
            },
          });
        }),
        id: stockTakeItem.stockTakeID,
      };
    });

    // Execute mutations
    await Promise.all(
      responses.map(async (item) => {
        const result = await Promise.all(item.response);
        successStockCountIds = [];
        result?.forEach((x) => {
          if (x?.data) {
            successStockCountIds?.push(
              x?.data?.updateStockCount?.stockCount?.id
            );
          } else {
            failedStockCountIds?.push(
              x?.data?.updateStockCount?.stockCount?.id
            );
          }
        });
        if (successStockCountIds?.length > 0) {
          handleSuccess();
          deleteDraftStockTake(item.id, successStockCountIds);
        }
      })
    ).then(() => {
      setStockTakesDraft(getDraftStockTakes());
      if (failedStockCountIds?.length > 0) {
        setShowUnSaved(true);
      }
    });
  };

  const notify = (message: string, toastType: TypeOptions) =>
    toast(message, { type: toastType, toastId: 'stockCountToast' });

  const handleSuccess = () => {
    notify(t('common.successMessage'), 'info');
  };

  const [updateQuantity, { loading: mutationLoading }] = useMutation(
    UPDATE_STOCK_COUNT_MUTATION,
    {
      onCompleted: handleSuccess,
      onError: () => {},
    }
  );

  const draftStockCountLength = stockTakesDraft?.length || 0;

  useEffect(() => {
    setStockTakesDraft(getDraftStockTakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout
      title={t('inventory.drafts.unsavedStockCounts')}
      parentPagePath={`/`}
    >
      <div className="flex justify-between mt-5 items-center">
        <p className="font-bold text-xl text-gray-700">
          {t('inventory.drafts.unsavedStockCounts')}
        </p>
        {draftStockCountLength > 0 && (
          <Button
            color="primary"
            variant="rounded"
            disabled={!online}
            handleClick={syncAllDraftStockTake}
            loading={mutationLoading}
          >
            {t('inventory.drafts.syncAll')}
          </Button>
        )}
      </div>
      {showUnSaved && stockTakesDraft && (
        <SyncError
          stockTakesDraft={stockTakesDraft}
          displayStockTakeLbl={true}
        />
      )}
      {draftStockCountLength > 0 ? (
        <Card>
          <DraftStockCountList drafts={stockTakesDraft} />
        </Card>
      ) : (
        <div className="flex justify-center mt-5">
          {t('inventory.drafts.noDraftAvailable')}
        </div>
      )}
    </DefaultLayout>
  );
}

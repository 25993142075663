import { User } from 'hooks/useSession';

interface UserAvatarProps {
  user: User;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { full_name } = props.user;
  const initials: string = full_name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <div className="h-28 w-28 border-blue border-2 flex items-center justify-center rounded-full">
      <div className="bg-blue h-24 w-24 text-white text-center font-extrabold flex items-center justify-center rounded-full">
        <span className="text-3xl font-bold text-white">{initials}</span>
      </div>
    </div>
  );
};

import React from 'react';
import Card from 'modules/common/components/Card';
import { QuantityValueIndicator } from '../QuantityValueIndicator';
import { StockCountEdgeNode } from '../StockCountList';

interface StockCountCardProps {
  stockCount: StockCountEdgeNode;
  stockLocationId: number;
  stockTakeId: number;
  position: number;
  offlineUpdatedValue?: number;
}

const formatPosition = (position: number) => {
  return position && <span>{`${position}. `}</span>;
};

export const StockCountCard: React.FC<StockCountCardProps> = ({
  stockCount,
  stockLocationId,
  stockTakeId,
  position,
  offlineUpdatedValue,
}) => {
  const {
    product: { concatenatedBrand, concatenatedDescription, itemPackName },
    quantity,
  } = stockCount;

  return (
    <Card
      to={`/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}/stock_count/${stockCount.id}`}
    >
      <div className="flex justify-between items-center">
        <div className="w-full">
          <p className="font-medium text-xl">
            {formatPosition(position)}
            {concatenatedBrand && `${concatenatedBrand}: `}
            <span className=" text-blue">{concatenatedDescription}</span>
          </p>
        </div>
        <QuantityValueIndicator
          quantity={quantity}
          itemPackName={itemPackName}
          offlineUpdatedValue={offlineUpdatedValue}
        />
      </div>
    </Card>
  );
};

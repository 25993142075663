/* eslint-disable @typescript-eslint/no-explicit-any */
import { filter } from 'lodash';
import sortArray from 'hooks/useOfflineSort';
import useFilter from 'hooks/useOfflineFilter';
import { FieldFunctionOptions } from '@apollo/client';
import {
  relayStylePagination,
  TRelayEdge,
  TRelayPageInfo,
} from '@apollo/client/utilities/policies/pagination';

export type TExistingRelay<TNode> = Readonly<{
  edges: TRelayEdge<TNode>[];
  pageInfo: TRelayPageInfo;
}>;

export type TIncomingRelay<TNode> = {
  edges: TRelayEdge<TNode>[];
  pageInfo: TRelayPageInfo;
};

export default function useCacheStoreData() {
  const { filterNode } = useFilter();

  const { merge: relayMerge, read: relayRead } = relayStylePagination();

  const merge = (
    existing: any,
    incoming: any,
    options: FieldFunctionOptions
  ) => {
    let { readField } = options;

    // User clicked Refresh first time, download all pages
    if (isNotAvailableOfflineYet(options))
      return (relayMerge as any)(existing, incoming, options);

    // User has clicked Refresh before.
    // Adding any new incoming edges
    let merged = existing?.edges ? existing?.edges : [];

    // Obtain a Set of all existing edge IDs.
    const existingIdSet = new Set(
      merged.map((edge: any) => readField('id', edge.node))
    );

    // Remove incoming edges already present in the existing data.
    const incomingEdges = incoming.edges.filter(
      (edge: any) => !existingIdSet.has(readField('id', edge?.node))
    );

    // Find the index of the edge just before the incoming page of edges.
    merged = merged.concat(incomingEdges);

    return { ...incoming, edges: merged };
  };

  const read = (existing: any, options: FieldFunctionOptions) => {
    if (isNotAvailableOfflineYet(options))
      return (relayRead as any)(existing, options);

    const cacheData = options.cache.extract();
    let allExistingData = existing?.edges?.map((e: any) => {
      return { ...e, node: cacheData[e.node.__ref] };
    });

    allExistingData = allExistingData?.map((e: any) => {
      return {
        ...e,
        node: getExpandedNode(cacheData, e, options),
      };
    });
    return withFilterAndSort(existing, options, allExistingData);
  };

  const getExpandedNode = (
    cacheData: any,
    e: any,
    options: FieldFunctionOptions
  ) => {
    if (options.fieldName === 'stockCounts')
      return { ...e.node, product: cacheData[e.node?.product?.__ref] };
    return e.node;
  };

  //TODO: This can be improved by removing isAvailableOffline from query parameter and use other approach to manage internally.
  const isNotAvailableOfflineYet = (options: FieldFunctionOptions) => {
    return !options?.variables?.isAvailableOffline;
  };

  // TODO: extract filter logic, make fields configurable
  // TODO: extract sort logic
  const withFilterAndSort = (
    existing: any,
    options: FieldFunctionOptions,
    allExistingData: any
  ) => {
    if (
      (options?.args?.filter?.q?.length > 0 &&
        options?.args?.filter?.q[0].value) ||
      options?.args?.sort?.property
    ) {
      let filteredData = null;
      if (
        options?.args?.filter?.q &&
        options?.args?.filter?.q.length > 0 &&
        options?.args?.filter?.q[0].value
      ) {
        const searchText = options?.args?.filter?.q[0].value.toLowerCase();
        filteredData = filter(allExistingData || [], function ({ node }) {
          return (
            filterNode(node.name, searchText) || //Need to add check of filter location
            filterNode(node?.product?.concatenatedDescription, searchText) ||
            filterNode(node?.product?.concatenatedBrand, searchText)
          );
        });
      }

      if (options?.args?.sort?.property) {
        filteredData = sortArray(options?.args?.sort, {
          ...existing,
          edges: filteredData || allExistingData,
        });
      }
      return {
        ...existing,
        edges: filteredData,
      };
    }
  };

  return {
    merge,
    read,
  };
}

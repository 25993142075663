import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Menu, ChevronLeft, XOutline } from 'heroicons-react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useSession } from 'hooks/useSession';
import NavigationSidebar from 'modules/common/components/NavigationSidebar';
import useStockCountDraft from 'hooks/useStockCountDraft';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import ConfirmModal from '../ConfirmModal';

interface DefaultLayoutProps {
  children: React.ReactNode;
  title: String;
  subTitle?: String;
  parentPagePath?: string;
  handleBack?: () => void;
  disableScroll?: Boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
  const {
    children,
    title,
    subTitle,
    parentPagePath,
    handleBack,
    disableScroll,
  } = props;
  let history = useHistory();

  const [showMenu, setShowMenu] = useState(false);
  const { user, signOut } = useAuth();
  const { organisation } = useSession();
  const [warning, setWarning] = useState(false);
  const { draftStockCountLength } = useStockCountDraft();
  const { t } = useTranslation();

  const goBack = () => {
    if (parentPagePath) history.push(parentPagePath);
    if (handleBack) handleBack();
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div
      className={`flex flex-col ${
        showMenu || disableScroll ? `overflow-y-hidden h-full` : ''
      }`}
    >
      <div
        className={`sticky top-0 w-full bg-white flex items-center justify-between h-16 shadow-sm px-3 ${
          !disableScroll ? `z-40` : ''
        }`}
      >
        <div className={parentPagePath || handleBack ? 'visible' : 'invisible'}>
          <Button handleClick={goBack}>
            <ChevronLeft />
          </Button>
        </div>
        <div>
          <h1 className="text-center font-bold">
            {title || t('common.siteTitle')}
          </h1>
          {subTitle ? <h2 className="text-xs text-gray">{subTitle}</h2> : null}
        </div>
        <div className={handleBack ? 'invisible' : 'visible'}>
          <Button handleClick={toggleMenu}>
            <Menu />
          </Button>
        </div>
      </div>

      <section className="py-2 px-3">{children}</section>

      {warning && (
        <ConfirmModal
          toggleModal={(value: boolean) => setWarning(value)}
          show={warning}
          warningMessage={t('access.signOut.warningMessage')}
          confirmMessage={t('access.signOut.confirm')}
          handleConfirm={signOut}
        />
      )}

      <Transition
        show={showMenu}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-50"
      >
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <section
          className="absolute inset-y-0 right-0 pl-10 max-w-full flex"
          aria-labelledby="slide-over-heading"
        >
          <div className="relative w-screen max-w-md">
            <div className="absolute top-0 right-10 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
              <Button handleClick={toggleMenu}>
                <XOutline />
              </Button>
            </div>
            <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-auto">
              <NavigationSidebar
                user={user}
                organisation={organisation}
                setWarning={() => {
                  setWarning(true);
                  setShowMenu(false);
                }}
                draftStockCountLength={draftStockCountLength}
              />
            </div>
          </div>
        </section>
      </Transition>
    </div>
  );
};

export default DefaultLayout;

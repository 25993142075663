import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RansackSort } from 'hooks/useRansackSort';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import { LastStockTakeData } from '../StockLocationsList';
import StockLocationsListItemMini from '../StockLocationsListItemMini';

export const STOCK_LOCATIONS_QUERY = gql`
  query StockLocationsQuery(
    $first: Int
    $sort: [RansackSortType!]
    $holderId: Int
  ) {
    holder(id: $holderId) {
      id
      stockLocations(first: $first, sort: $sort) {
        edges {
          cursor
          node {
            id
            name
            stockTakeStatus
            balanceValue
            lastStockTake {
              closable
              closedAt
              id
              periodEndingOn
            }
          }
        }
      }
    }
  }
`;

export interface StockLocation {
  id: string;
  name: string;
  stockTakeStatus: string;
  balanceValue: string;
  lastStockTake: LastStockTakeData;
}
interface StockLocationsData {
  holder: {
    stockLocations: {
      edges: Array<{ cursor: string; node: StockLocation }>;
    };
  };
}

export interface StockLocationsListMiniProps {
  first?: number;
  sort?: RansackSort;
  holderId?: number;
}

const StockLocationsListMini: React.FC<StockLocationsListMiniProps> = ({
  first,
  sort,
  holderId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [
    getStockLocations,
    { loading, error, data, refetch },
  ] = useLazyQuery<StockLocationsData>(STOCK_LOCATIONS_QUERY, {
    variables: {
      first,
      sort: sort && {
        property: sort.property,
        direction: sort.direction,
      },
      holderId,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
  });

  // Initial load
  useEffect(() => {
    getStockLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorMessage error={error} retry={refetch} />;
  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const handleClick = (to: string) => {
    history.push(to, { from: history.location.pathname });
  };

  const { stockLocations } = data.holder;

  return (
    <ul>
      {stockLocations.edges.map(
        ({ node }, index) =>
          index < 5 && (
            <StockLocationsListItemMini
              key={index}
              node={node}
              onClick={handleClick}
            ></StockLocationsListItemMini>
          )
      )}
    </ul>
  );
};
export default StockLocationsListMini;

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { useNetworkState } from 'react-use';
import { toast } from 'react-toastify';
import { gql, useLazyQuery } from '@apollo/client';
import { map, join } from 'lodash';
import { EyeOff } from 'heroicons-react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'modules/common/components/DefaultLayout';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import StockTakePeriodLink from 'modules/inventory/components/StockTakePeriodLink';
import useMoney from 'hooks/useMoney';
import { useFetchData } from 'hooks/useOfflineSync';
import {
  DescriptionList,
  DescriptionListItem,
} from 'modules/common/components/DescriptionList';
import { ReactComponent as LoaderImg } from 'assets/img/Loader.svg';

import { useSession } from 'hooks/useSession';
import StockTakes from '../../components/StockTakes';

export const StockLocationFragment = gql`
  fragment stockLocationFragment on StockLocation {
    id
    name
    departments {
      nodes {
        id
        name
      }
    }
    balanceValue
    lastStockTake {
      id
      periodEndingOn
      partial
      blind
    }
    stockTakeStatus
    stockTakes {
      edges {
        node {
          id
          stockLocationId
          closedAt
        }
      }
    }
  }
`;

export default function StockLocation() {
  const { format: money } = useMoney();
  const { id } = useParams<{
    id: string;
  }>();
  const { t } = useTranslation();
  const history = useHistory();
  const [disableScroll, setDisableScroll] = useState(true);
  const { online } = useNetworkState();
  const { fetch, syncStatus, lastSynced } = useFetchData(Number(id));
  const { holder } = useSession();

  const STOCK_LOCATION_QUERY = gql`
    query stockLocationQuery($id: Int!, $holderId: Int) {
      holder(id: $holderId) {
        id
        stockLocation(id: $id) {
          ...stockLocationFragment
        }
      }
    }
    ${StockLocationFragment}
  `;

  const lastRefresh = lastSynced?.[id || '']
    ? new Intl.DateTimeFormat('en-AU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(lastSynced?.[id || '']))
    : '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state } = history.location as any;

  useEffect(
    () => {
      if (syncStatus === 'error') {
        toast(t('common.retryErrorMessage'), {
          type: 'error',
        });
      } else if (syncStatus === 'success') {
        toast(t('common.offlineSync.success'), {
          type: 'success',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [syncStatus]
  );

  useEffect(() => {
    getStockLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getStockLocation, { loading, error, data, refetch }] = useLazyQuery(
    STOCK_LOCATION_QUERY,
    {
      variables: {
        id: Number(id),
        holderId: holder?.id,
      },
      fetchPolicy: online ? 'network-only' : 'cache-first',
      context: {
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
      },
    }
  );

  const stockLocation = data?.holder?.stockLocation;

  return (
    <DefaultLayout
      title={stockLocation?.name}
      parentPagePath={state?.from || `/inventory/stock_locations`}
      disableScroll={disableScroll}
    >
      {
        <>
          <Helmet>
            <title>
              {stockLocation?.name || t('inventory.stockLocations.pageTitle')} |{' '}
              {t('common.siteTitle')}{' '}
            </title>
          </Helmet>
          {error && <ErrorMessage error={error} retry={refetch} />}
          {((loading && !data) || !data) && !error && (
            <p>{t('common.loading')}</p>
          )}
          {stockLocation && (
            <>
              <DescriptionList>
                <DescriptionListItem label={t('inventory.stockLocation.name')}>
                  {stockLocation.name}
                </DescriptionListItem>
                <DescriptionListItem
                  label={t('inventory.stockLocation.departments')}
                >
                  {stockLocation.departments.length
                    ? join(map(stockLocation.departments, 'name'), ', ')
                    : ''}
                </DescriptionListItem>
                <DescriptionListItem
                  label={t('inventory.stockLocation.balance')}
                >
                  {money(stockLocation.balanceValue)}
                </DescriptionListItem>
                <DescriptionListItem
                  label={t('inventory.stockLocation.turnOver')}
                >
                  -
                </DescriptionListItem>
                <DescriptionListItem
                  label={t('inventory.stockLocation.stockTakePeriod')}
                >
                  {stockLocation.lastStockTake.id ? (
                    <StockTakePeriodLink
                      stockLocationId={String(id)}
                      stockTake={stockLocation.lastStockTake}
                    />
                  ) : (
                    '-'
                  )}
                </DescriptionListItem>
                <DescriptionListItem
                  label={t('inventory.stockLocation.availableOffline')}
                >
                  <>
                    {lastRefresh && <div className="mr-2">{lastRefresh}</div>}
                    {syncStatus === 'loading' ? (
                      <LoaderImg />
                    ) : (
                      <div
                        className="text-blue underline cursor-pointer"
                        onClick={() => {
                          fetch(Number(id));
                        }}
                      >
                        {t('common.refresh')}
                      </div>
                    )}
                  </>
                </DescriptionListItem>
                {stockLocation.lastStockTake.partial && (
                  <DescriptionListItem
                    label={t('inventory.stockLocation.partial')}
                  >
                    <input
                      type="checkbox"
                      className="h-5 w-5 text-bg-blue-500"
                      disabled
                      ref={(el) => el && (el.indeterminate = true)}
                    />
                  </DescriptionListItem>
                )}

                {stockLocation.lastStockTake.blind && (
                  <DescriptionListItem
                    label={t('inventory.stockLocation.blind')}
                  >
                    <EyeOff className="ml-auto md:ml-0" />
                  </DescriptionListItem>
                )}
              </DescriptionList>
              <div className="mt-10">
                {id && holder?.id && (
                  <StockTakes
                    stockLocationId={id}
                    setDisableScroll={() => setDisableScroll(!disableScroll)}
                    lastSynced={lastSynced}
                    holderId={holder?.id}
                  />
                )}
              </div>
            </>
          )}
        </>
      }
    </DefaultLayout>
  );
}

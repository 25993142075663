import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../Input';

export interface CheckboxProps extends InputProps {
  checked?: boolean;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  register,
  required,
  errors,
  onChange,
  checked = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-bg-blue-500 rounded-full"
          {...register(name, { required, onChange })}
          defaultChecked={checked}
        />
        <span className="ml-2 text-gray-700">{label}</span>
      </label>
      {errors ? (
        <div className="text-sm text-red-600">
          {label
            ? `${label} ${t('common.isRequiredField')}`
            : t('common.requiredField')}{' '}
        </div>
      ) : null}
    </>
  );
};

export default Checkbox;

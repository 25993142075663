import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';
import React, { useEffect } from 'react';
import useSort, { sortProperties } from 'hooks/useRansackSort';
import { preservedSorts } from 'apollo/ApolloProviderWrapper';
import { usePreservedSort } from 'hooks/usePreservedSort';
import StockTakesList from 'modules/inventory/components/StockTakesList';
import { SyncDateType } from 'hooks/useOfflineSync';

interface StockTakesParams {
  stockLocationId: string;
  setDisableScroll: () => void;
  lastSynced: SyncDateType;
  holderId?: number;
}

export const StockTakes: React.FC<StockTakesParams> = ({
  stockLocationId,
  setDisableScroll,
  lastSynced,
  holderId,
}) => {
  const { t } = useTranslation();
  const { online } = useNetworkState();

  const { handleSortingChange } = usePreservedSort(preservedSorts, {
    stockLocationId,
  });

  const sortOptions = sortProperties([
    'createdAt',
    'closedAt',
    'periodEndingOn',
  ]);

  const createdAtDesc = sortOptions[1];

  const {
    ransackSort = createdAtDesc,
    SortMenu,
    sortByScreenVisibile,
  } = useSort();

  useEffect(() => {
    //Preserve default sort on page load.
    handleSortingChange(ransackSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisableScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByScreenVisibile]);

  return (
    <>
      <div className="flex justify-between mb-2 items-center">
        <p className="font-bold text-xl text-gray-700">
          {t('inventory.stockTakes.pageTitle')}
        </p>
        <SortMenu
          sortOptions={sortOptions}
          defaultSorting={createdAtDesc}
          onSortingChange={handleSortingChange}
        />
      </div>
      <StockTakesList
        id={parseInt(stockLocationId)}
        sort={ransackSort}
        online={online}
        lastSyncDate={lastSynced?.[stockLocationId]}
        holderId={holderId}
      />
    </>
  );
};

export default StockTakes;

import { gql, useMutation } from '@apollo/client';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { SwitchOrganisation } from 'hooks/useSession';
import OrganisationTreeNode from '../OrganisationTreeNode';
import { fragment } from '../OrganisationSwitcherList';

interface ChangeOrganisation {
  changeOrganisation: {
    currentOrganisation: SwitchOrganisation;
  };
}

interface OrganisationTreeProps {
  depth?: number;
  organisations: Array<SwitchOrganisation>;
}

const OrganisationTree: React.FC<OrganisationTreeProps> = ({
  depth = 1,
  organisations = [],
}) => {
  const { user, setUser } = useAuth();
  const history = useHistory();

  const UPDATE_MUTATION = gql`
    mutation changeOrganisation($id: Int!) {
      changeOrganisation(input: { id: $id }) {
        currentOrganisation {
          ...organisationNode
        }
      }
    }
    ${fragment}
  `;

  const setSessionOrganisation = ({
    changeOrganisation,
  }: ChangeOrganisation) => {
    const { currentOrganisation } = changeOrganisation;
    setUser({ ...user, changedOrganisation: currentOrganisation.id });
    history.push('/');
  };

  const [changeOrganisation] = useMutation(UPDATE_MUTATION, {
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/access/api/graphql`,
    },
    onCompleted: setSessionOrganisation,
    onError: () => {},
  });

  return (
    <>
      {organisations.map((organisation: SwitchOrganisation) => (
        <OrganisationTreeNode
          key={organisation.id}
          depth={depth}
          onClick={(id: string) =>
            changeOrganisation({
              variables: {
                id: parseInt(id),
              },
            })
          }
          organisation={organisation}
        />
      ))}
    </>
  );
};

export default OrganisationTree;

import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { createCtx } from 'hooks/createCtx';
import useLocalStorage from 'hooks/useLocalStorage';
import { User } from 'hooks/useSession';

export interface Credentials {
  email: string;
  password: string;
}

interface Response {
  success: boolean;
  errors: string;
  data: {
    attributes: User;
  };
}

interface AuthContext {
  authHeaders: AuthHeaders;
  user: User;
  signIn: Function;
  signOut: Function;
  setUser: Function;
}

interface AuthHeaders {
  'access-token'?: string;
  client?: string;
  expiry?: string;
  uid?: string;
}

const [useAuth, AuthContextProvider] = createCtx<AuthContext>();
export { useAuth };

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContextProvider value={auth}>{children}</AuthContextProvider>;
};

const restApiAuth = {
  authHeaders: {},
  isAuthenticated: false,
  signIn(data: Credentials) {
    return new Promise<Response>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_NINJA_API_HOST}/access/api/auth/sign_in`, {
        method: 'POST',
        headers: {
          Accept: 'application/vnd.mbapi.v2+json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      })
        .then((response) => {
          restApiAuth.authHeaders = {
            'access-token': response.headers.get('access-token'),
            client: response.headers.get('client'),
            expiry: response.headers.get('expiry'),
            uid: response.headers.get('uid'),
          };
          restApiAuth.isAuthenticated = true;
          resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  signOut() {
    return new Promise<Response>((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_NINJA_API_HOST}/access/api/auth/sign_out`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/vnd.mbapi.v2+json',
            'Content-Type': 'application/json; charset=UTF-8',
            ...restApiAuth.authHeaders,
          },
        }
      )
        .then((response) => {
          restApiAuth.authHeaders = {};
          restApiAuth.isAuthenticated = false;
          resolve(response.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export function useProvideAuth() {
  const [authHeaders, setAuthHeaders] = useLocalStorage<AuthHeaders>(
    'authHeaders',
    {}
  );
  const [user, setUser] = useLocalStorage('user', {} as User);
  const [prevUser, setPreviousUser] = useLocalStorage('prevUser', '');

  useEffect(() => {
    hasExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasExpired = () => {
    const expiryTime = authHeaders?.expiry;
    if (expiryTime) {
      const expiryTimeInMillisecond = parseInt(expiryTime) * 1000;
      if (Date.now() > expiryTimeInMillisecond) signOut();
    }
  };

  const signIn = async (credentials: Credentials) => {
    try {
      const response = await restApiAuth.signIn(credentials);
      if (response.success === false) {
        throw new Error(response.errors);
      }
      setAuthHeaders(restApiAuth.authHeaders);
      setUser(response.data.attributes);
      Sentry.setUser({
        ...response.data.attributes,
        id: response.data.attributes.id.toString(),
      });
      if (response.data.attributes?.id !== parseInt(prevUser)) {
        const keysToRemove = [
          'apollo-cache-persist',
          'stockTakeSyncDate',
          'stockCountsSyncDate',
        ];
        keysToRemove.forEach((k) => localStorage.removeItem(k));
      }
      return response;
    } catch (error) {
      setUser({} as User);
      setAuthHeaders({});
      throw error;
    }
  };

  const signOut = async () => {
    try {
      setPreviousUser(JSON.stringify(user?.id));
      restApiAuth.authHeaders = authHeaders;
      await restApiAuth.signOut();
    } catch (error) {
      throw error;
    } finally {
      setUser({} as User);
      setAuthHeaders({});
      Sentry.setUser(null);
    }
  };

  return {
    user,
    authHeaders,
    signIn,
    signOut,
    setUser,
  };
}

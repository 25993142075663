import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useSession } from 'hooks/useSession';
import { useForm } from 'react-hook-form';
import {
  Context,
  SwitcherContext,
} from 'modules/access/components/OrganisationSwitcher';
import { debounce } from 'lodash';
import Checkbox from '../Checkbox';
import SearchInput from '../SearchInput';

const ScopeCheckbox = () => {
  const user = useSession();
  const { isScoped, toggleIsScoped }: Context = useContext(SwitcherContext);
  const { register } = useForm();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <div>
      {toggleIsScoped && (
        <Checkbox
          checked={isScoped}
          label={t('access.organisationSwitcher.toggleScope')}
          onChange={() => toggleIsScoped(!isScoped)}
          name="organisationcheckbox"
          register={register}
        />
      )}
    </div>
  );
};

const OrganisationSwitcherHeader = () => {
  const { updateSearchText }: Context = useContext(SwitcherContext);
  const { register, getValues } = useForm();
  const { t } = useTranslation();

  const delayedQuery = debounce(
    (q) => updateSearchText && updateSearchText(q),
    500
  );

  return (
    <div className="px-3 py-2">
      <p className="mb-2 font-semibold">
        {t('access.organisationSwitcher.header')}
      </p>
      {
        <SearchInput
          name="search"
          autoFocus={true}
          onChange={() => delayedQuery(getValues('search'))}
          placeholder={t('access.organisationSwitcher.searchPlaceholder')}
          register={register}
          inputFieldStyle={'mb-5'}
        />
      }

      <ScopeCheckbox />
    </div>
  );
};

export default OrganisationSwitcherHeader;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { DraftStockTake } from 'hooks/useStockCountDraft';

interface DraftStockCountCardProps {
  stockTakeDraft: DraftStockTake;
}

export const DraftStockCountCard: React.FC<DraftStockCountCardProps> = ({
  stockTakeDraft,
}) => {
  const { stockLocationId, name, stockTakeID, stockCounts } = stockTakeDraft;
  const history = useHistory();

  const handleClick = () => {
    history.push(
      `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeID}`
    );
  };

  return (
    <li
      className="py-2 flex my-4 border-b-2 border-gray-200  px-5 cursor-pointer"
      onClick={handleClick}
    >
      <div className="font-bold text-blue text-lg">{name}</div>
      <div className="font-bold ml-5 text-lg">({stockCounts.length})</div>
    </li>
  );
};

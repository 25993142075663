import { ApolloLink } from '@apollo/client';

export const addAuthHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        ...JSON.parse(localStorage.getItem('authHeaders') || '{}'),
      },
    };
  });
  return forward(operation);
});

export const storeAuthHeadersLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const authHeaders = {
        'access-token': headers.get('access-token'),
        client: headers.get('client'),
        expiry: headers.get('expiry'),
        uid: headers.get('uid'),
      };
      window.localStorage.setItem('authHeaders', JSON.stringify(authHeaders));
    }
    return response;
  });
});

import { ReactComponent as LoaderImg } from 'assets/img/Loader.svg';

type ButtonColor = 'primary' | 'default';
type ButtonVariant = 'rounded' | 'default';

export interface ButtonProps {
  children: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonVariant;
  color?: ButtonColor;
  htmlType?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
  primaryBorder?: boolean;
  customWidth?: string;
  loaderColor?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  handleClick,
  loading,
  htmlType = 'button',
  color,
  variant,
  disabled,
  primaryBorder = false,
  customWidth = '',
  loaderColor = 'text-white',
}) => {
  const baseClassNames =
    'flex items-center justify-center shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';

  const getButtonColorClassNames = () => {
    switch (color) {
      case 'primary':
        let btnColor = 'bg-blue-500 hover:bg-blue-600';
        if (disabled) {
          btnColor = 'bg-blue-300 cursor-default';
        }
        return `${btnColor} text-white`;
      default:
        let borderColor = 'border-gray-300';
        if (primaryBorder) borderColor = 'border-blue';
        return `bg-white border ${borderColor} text-gray-700 hover:bg-gray-50`;
    }
  };

  const getButtonVariantClassNames = () => {
    switch (variant) {
      case 'rounded':
        return 'w-24 h-12 rounded-full shadow-md';
      default:
        return 'px-4 py-2 rounded-md';
    }
  };

  const getButtonClassNames = () => {
    return `${baseClassNames} ${getButtonVariantClassNames()} ${getButtonColorClassNames()} ${customWidth}`;
  };

  return (
    <button
      type={htmlType}
      onClick={handleClick}
      className={getButtonClassNames()}
      disabled={disabled}
    >
      {loading && <LoaderImg className={`${loaderColor} w-4 h-4 mr-2`} />}
      {children}
    </button>
  );
};
export default Button;

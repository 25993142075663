import { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNetworkState } from 'react-use';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSession } from 'hooks/useSession';
import {
  BarcodeReader,
  BarcodeStatus,
} from 'modules/common/components/BarcodeReader';
import { BarcodeStockCountMapper } from 'modules/inventory/components/StockCountList';

interface StockLevelByBarcode {
  holder: {
    location: {
      barcode: {
        id: number;
        stockCount: {
          id: number;
        };
      };
    };
  };
}

export const BarcodeScannerPage: React.FC = () => {
  const { stockTakeId, stockLocationId } = useParams<{
    stockTakeId: string;
    stockLocationId: string;
  }>();
  const history = useHistory();
  const { online } = useNetworkState();
  const [barcodeStatus, setbarcodeStatus] = useState<BarcodeStatus>(
    'detecting'
  );
  const { t } = useTranslation();
  const { holder } = useSession();
  const [syncBarcodes] = useLocalStorage<Array<BarcodeStockCountMapper>>(
    'syncBarcodes',
    []
  );

  const STOCK_LEVEL_BY_BARCODE = gql`
    query stockLevelQuery(
      $holderId: Int!
      $locationId: Int!
      $barcodeBody: String!
      $stockTakeId: Int!
    ) {
      holder(id: $holderId) {
        location(id: $locationId) {
          id
          barcode(body: $barcodeBody) {
            id
            stockCount(stockTakeId: $stockTakeId) {
              id
            }
          }
        }
      }
    }
  `;

  const handleBarcodeError = () => {
    setTimeout(() => setbarcodeStatus('detecting'), 500);
    toast(t('inventory.scanBarcode.productNotFound'), {
      type: 'error',
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: 'noProductForBarcode',
      autoClose: 3000,
    });
  };

  const handleBarcodeSuccess = (data: StockLevelByBarcode) => {
    const { id } = data.holder.location.barcode.stockCount;
    redirectToStockLocations(id);
  };

  const [performSearch] = useLazyQuery<StockLevelByBarcode>(
    STOCK_LEVEL_BY_BARCODE,
    {
      onCompleted: handleBarcodeSuccess,
      onError: handleBarcodeError,
      context: {
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
      },
    }
  );

  const redirectToStockLocations = (id: number) => {
    history.push(
      `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}/stock_count/${id}`
    );
  };

  const offlineBarcodeLookUp = (value: string) => {
    let barcodeFound = false;
    find(syncBarcodes, (x) => {
      if (x?.body === value) {
        barcodeFound = true;
        redirectToStockLocations(x.stockCountId);
      }
    });
    return barcodeFound;
  };

  const handleCodeRead = (value: string) => {
    if (barcodeStatus === 'detecting') {
      setbarcodeStatus('searching');
      if (online) {
        return (
          offlineBarcodeLookUp(value) ||
          performSearch({
            variables: {
              barcodeBody: value,
              holderId: holder.id,
              locationId: Number(stockLocationId),
              stockTakeId: Number(stockTakeId),
            },
          })
        );
      } else {
        if (!offlineBarcodeLookUp(value)) {
          return handleBarcodeError();
        }
      }
    }
  };

  return (
    <BarcodeReader
      barcodeStatus={barcodeStatus}
      onCodeRead={(value) => handleCodeRead(value)}
      handleBack={() =>
        history.push(
          `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}`
        )
      }
    />
  );
};

export default BarcodeScannerPage;

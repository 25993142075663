import { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

export interface ErrorType {
  type?: string;
  message?: string;
}
export interface InputProps {
  label?: string | null;
  name: string;
  errors?: ErrorType;
  defaultValue?: string | number;
  type?: string;
  placeholder?: string;
  inputFieldStyle?: string;
  inputBoxStyle?: string;
  autoFocus?: boolean;
  onChange?: (e: KeyboardEvent) => void;
}

export interface InputTextBoxProps extends InputProps {
  required?: boolean;
  secondaryIconProps?: {
    secondaryIcon: ReactChild;
    secondaryAction: () => void;
  };
  register: UseFormRegister<FieldValues>;
  readOnly?: boolean;
  min?: number;
  max?: number;
}

export const Input: React.FC<InputTextBoxProps> = ({
  label,
  name,
  register,
  defaultValue,
  required,
  errors,
  placeholder,
  type,
  inputBoxStyle = '',
  autoFocus,
  inputFieldStyle = 'mb-10',
  onChange,
  readOnly,
  min,
  max,
}) => {
  const { t } = useTranslation();

  const inputFieldClasses = classnames([
    'border',
    'focus-within:text-blue-500',
    'transition-all',
    'duration-500',
    'p-1',
    'relative',
    'rounded',
    inputFieldStyle,
    { 'focus-within:border-blue-500': !readOnly },
    { 'bg-gray-100 focus-within:border-blue-100': readOnly },
  ]);

  const inputClasses = classnames(
    'p-3',
    'outline-none',
    'block',
    'h-full',
    'w-full',
    'border-none',
    'focus:ring-transparent',
    inputBoxStyle,
    { 'text-gray-900': !readOnly },
    { 'focus-within:border-blue-200 bg-gray-100 text-gray-400': readOnly }
  );

  return (
    <>
      <div className={inputFieldClasses}>
        {label && (
          <div className="-mt-4 ml-4 absolute px-1 text-sm">
            <p>
              <label htmlFor={name} className="bg-white text-gray-500 px-1">
                {label}
              </label>
            </p>
          </div>
        )}

        <input
          {...register(name, { required, min, max, onChange })}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className={inputClasses}
        />
      </div>
      {errors ? ( //TODO: Extract to generic error message function
        <div className="text-sm text-red-600">
          {errors?.type === 'required'
            ? label
              ? `${label} ${t('common.isRequiredField')}`
              : t('common.requiredField')
            : errors?.type === 'min'
            ? `${t('common.maxValueMessage')} ${min}`
            : `${t('common.minValueMessage')} ${max}`}{' '}
        </div>
      ) : null}
    </>
  );
};

export default Input;

import { useTranslation } from 'react-i18next';
import Card from 'modules/common/components/Card';
import { useStockTakeColor } from 'hooks/useStockTakeColor';
import { StatusIndicator } from '../StockLocationStatusIndicator';
import { StockTakesNode } from '../StockTakesList';

interface StockTakesCardProps {
  node: StockTakesNode;
  stockLocationId: number;
  stockTakeStatus: string;
}

export const StockTakesCard: React.FC<StockTakesCardProps> = ({
  node,
  stockLocationId,
  stockTakeStatus,
}) => {
  const { t } = useTranslation();
  const { periodEndingOn, id, closedAt, blind, partial, closable } = node;

  const formattedPeriodEndingOn = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(periodEndingOn));

  const formattedClosedAt = closedAt
    ? new Intl.DateTimeFormat('en-AU', {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(new Date(closedAt))
    : '';

  const color = useStockTakeColor(true, closable);

  return (
    <Card
      to={`/inventory/stock_locations/${stockLocationId}/stock_takes/${id}`}
      color={!closedAt ? color : ''}
    >
      <div className="flex justify-between items-center">
        <div className="w-full mx-3">
          <div className="font-bold text-blue">{formattedPeriodEndingOn}</div>
          <ul className=" grid grid-rows-1 items-center text-gray-500 flex flex-wrap text-xs">
            {!closedAt && <li className="mr-4">{stockTakeStatus}</li>}
            {closedAt && (
              <li className="mr-3">
                <span>{t('inventory.stockLocation.closed')}</span>
                <span className="pl-1">{formattedClosedAt}</span>
              </li>
            )}

            <div className="items-center text-gray-500 flex flex-wrap text-xs">
              {blind && (
                <li className="mt-2 mr-2">
                  <span className="p-1 bg-blue-400 rounded-full text-white">
                    {t('inventory.stockLocation.blind')}
                  </span>
                </li>
              )}
              {partial && (
                <li className="mt-2">
                  <span className="p-1 bg-blue-400 rounded-full text-white">
                    {t('inventory.stockLocation.partial')}
                  </span>
                </li>
              )}
            </div>
          </ul>
        </div>
        <div>{closedAt && <StatusIndicator closedAt={closedAt} />}</div>
      </div>
    </Card>
  );
};

import { ReactComponent as SearchIcon } from 'assets/img/SearchIcon.svg';
import Input, { InputTextBoxProps } from '../Input';

export const SearchInput: React.FC<InputTextBoxProps> = ({
  name,
  register,
  errors,
  placeholder,
  defaultValue,
  onChange,
  secondaryIconProps,
  inputFieldStyle,
}) => {
  let inputBoxStyle =
    'py-2 text-sm text-white rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900 border-none focus:ring-transparent';
  const secondaryAction = secondaryIconProps?.secondaryAction;
  const secondaryIcon = secondaryIconProps?.secondaryIcon;
  if (secondaryAction) {
    inputBoxStyle += ' pr-10';
  }
  return (
    <div className="relative">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2 z-10">
        <button
          type="submit"
          className="p-1 focus:outline-none focus:shadow-outline"
        >
          <SearchIcon />
        </button>
      </span>
      <Input
        name={name}
        register={register}
        errors={errors}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        inputBoxStyle={inputBoxStyle}
        type={'search'}
        inputFieldStyle={inputFieldStyle}
      />
      {secondaryAction && (
        <span className="absolute inset-y-0 right-2 flex items-center pl-2 z-10">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
            onClick={secondaryAction}
          >
            {secondaryIcon}
          </button>
        </span>
      )}
    </div>
  );
};

export default SearchInput;

import { useEffect, useCallback } from 'react';
import { UseFormSetFocus, FieldValues } from 'react-hook-form';

export default function useHoneywellFocus(
  setFocus: UseFormSetFocus<FieldValues>
) {
  const handleScannerKey = useCallback(
    (e: KeyboardEvent) => {
      if (!isScannerButton(e)) return;
      setFocus('stockLevelSearch');
    },
    [setFocus]
  );

  const isScannerButton = (e: KeyboardEvent) => {
    return (
      e?.target instanceof window.HTMLElement &&
      e.key === 'Unidentified' &&
      e.location === 0 &&
      e.code === ''
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', handleScannerKey);
    return () => {
      window.removeEventListener('keydown', handleScannerKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

import { useTranslation } from 'react-i18next';
interface StatusIndicatorProps {
  closedAt: string;
  smallSize?: boolean;
}
export const StatusIndicator: React.FC<StatusIndicatorProps> = (props) => {
  const { t } = useTranslation();
  const { closedAt, smallSize } = props;
  const borderBoxClasses = `${
    smallSize ? 'w-24 h-8' : 'h-16 w-16 md:h-20 md:w-20'
  } xs:text-sm md:text-lg flex items-center p-2 justify-center rounded-3xl uppercase font-bold`;
  return closedAt ? (
    <div className={`${borderBoxClasses} bg-green-100 text-green-600`}>
      {t('inventory.stockLocation.closed')}
    </div>
  ) : (
    <div className={`${borderBoxClasses} bg-red-100 text-red-600`}>
      {t('inventory.stockLocation.open')}
    </div>
  );
};

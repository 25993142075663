import { useAuth } from 'hooks/useAuth';
import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';
import { OfflineFetchMessage } from '../OfflineFetchMessage';

interface ErrorMessageProps {
  error: ApolloError | Error;
  retry?: Function;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, retry }) => {
  const { online } = useNetworkState();
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const { graphQLErrors } = ('graphQLErrors' in error && error) || {};
  const { networkError } = ('networkError' in error && error) || {};
  const statusCode =
    networkError && 'statusCode' in networkError && networkError.statusCode;

  const errorOccured = !!(
    error.message === 'Failed to fetch' ||
    statusCode ||
    graphQLErrors?.length
  );

  useEffect(() => {
    if (online && retry && !errorOccured) {
      retry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  if (statusCode === 401) {
    signOut();
  }

  if (!online) return <OfflineFetchMessage fetchMore={retry} online={online} />;

  if (errorOccured) {
    return (
      <div className="text-red-600">
        <Trans
          i18nKey="common.internalError"
          values={{ supportEmailAddress: t('common.supportEmailAddress') }}
          components={[
            <a href={`mailto:${t('common.supportEmailAddress')}`}>
              {t('common.supportEmailAddress')}
            </a>,
          ]}
        ></Trans>
      </div>
    );
  } else return <></>;
};

export default ErrorMessage;

import { useState } from 'react';
import { debounce } from 'lodash';
export interface RansackFilter {
  q?: Array<{
    property: string;
    matcher: string;
    value: string;
  }>;
}

export default function useRansackFilter(delay: number) {
  const [filter, setFilterValue] = useState<RansackFilter>();

  const debouncedQuery = debounce((q) => {
    const filterParam = {
      q,
    };
    setFilterValue(filterParam);
  }, delay);

  return {
    filter,
    debouncedQuery,
  };
}

import { useTranslation } from 'react-i18next';
import Card from 'modules/common/components/Card';
import { useStockTakeColor } from 'hooks/useStockTakeColor';
import { BadgeCheck } from 'heroicons-react';
import useLastSyncDate from 'hooks/useLastSyncDate';
import { StatusIndicator } from '../StockLocationStatusIndicator';
import { StockLocation } from '../StockLocationsList';
import { MoneyValueCard } from '../../../common/components/MoneyValueCard';

interface StockLocationCardProps {
  node: StockLocation;
}

export const StockLocationCard: React.FC<StockLocationCardProps> = ({
  node,
}) => {
  const { t } = useTranslation();
  const { getLastSyncDate } = useLastSyncDate(node.id);
  const periodEndingOn = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(node.lastStockTake.periodEndingOn));

  const closedAt = node.lastStockTake.closedAt
    ? new Intl.DateTimeFormat('en-AU', {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(new Date(node.lastStockTake.closedAt))
    : '';

  const {
    stockTakeStatus,
    lastStockTake: { id, closable },
  } = node;

  const color = useStockTakeColor(id ? true : false, closable);

  return (
    <Card
      to={`/inventory/stock_locations/${node.id}`}
      color={!closedAt ? color : ''}
    >
      <div className="flex justify-between items-center">
        <div className="w-full">
          <div className="font-bold flex">
            <p className="text-blue">{node.name}</p>
            {getLastSyncDate() && <BadgeCheck className="ml-5" />}
          </div>
          <ul className="items-center text-gray-500 flex flex-wrap text-xs">
            {!node.lastStockTake.closedAt && (
              <li className="mr-4">{stockTakeStatus}</li>
            )}
            {node.lastStockTake.id && (
              <li className="mr-4">{periodEndingOn}</li>
            )}
            {node.lastStockTake.closedAt && (
              <li>
                {t('inventory.stockLocation.closed')} {closedAt}
              </li>
            )}
          </ul>
          <MoneyValueCard moneyValue={node.balanceValue} />
        </div>
        <div>
          {closedAt && node.lastStockTake.id && (
            <StatusIndicator closedAt={closedAt} />
          )}
        </div>
      </div>
    </Card>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

interface StockTakePeriodLinkProps {
  stockTake: { id: string; periodEndingOn: string };
  stockLocationId: string;
}

const StockTakePeriodLink: React.FC<StockTakePeriodLinkProps> = ({
  stockTake: { id, periodEndingOn },
  stockLocationId,
}) => {
  const month = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(periodEndingOn));

  return (
    <Link
      className="text-blue underline"
      to={`/inventory/stock_locations/${stockLocationId}/stock_takes/${id}`}
    >
      {month}
    </Link>
  );
};

export default StockTakePeriodLink;

import { BadgeCheck } from 'heroicons-react';
import useLastSyncDate from 'hooks/useLastSyncDate';
import { useStockTakeColor } from 'hooks/useStockTakeColor';
import { StockLocation } from '../StockLocationsListMini';
import { StatusIndicator } from '../StockLocationStatusIndicator';

export interface StockLocationsListItemMiniProps {
  node: StockLocation;
  onClick: Function;
}

const StockLocationsListItemMini: React.FC<StockLocationsListItemMiniProps> = ({
  node,
  onClick,
}) => {
  const { getLastSyncDate } = useLastSyncDate(parseInt(node.id));
  const color = useStockTakeColor(
    node.lastStockTake.id ? true : false,
    node.lastStockTake.closable
  );

  return (
    <li
      className={`py-2 flex justify-between my-4 border-b-2 border-gray-200 font-bold text-gray-600 text-lg px-5 cursor-pointer ${
        !node.lastStockTake.closedAt ? color : ''
      }`}
      onClick={() => onClick(`/inventory/stock_locations/${node.id}`)}
    >
      <div className="flex">
        {node.name}
        {getLastSyncDate() && <BadgeCheck className="ml-3" />}
      </div>
      {node.lastStockTake.id && node.lastStockTake.closedAt && (
        <StatusIndicator
          smallSize={true}
          closedAt={node.lastStockTake.closedAt}
        />
      )}
    </li>
  );
};
export default StockLocationsListItemMini;

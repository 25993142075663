import { TRelayPageInfo } from '@apollo/client/utilities/policies/pagination';
import React, { useEffect } from 'react';
import { Refresh } from 'heroicons-react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';
import { OfflineFetchMessage } from '../OfflineFetchMessage';

export interface LoadMoreProps extends React.HTMLProps<HTMLDivElement> {
  hasNextPage: TRelayPageInfo['hasNextPage'];
  fetchMore: Function;
}

export const LoadMore: React.FC<LoadMoreProps> = ({
  hasNextPage,
  fetchMore,
}) => {
  const THRESHOLD = [0.1];
  const { ref, inView } = useInView({ threshold: THRESHOLD });
  const { t } = useTranslation();
  const { online } = useNetworkState();

  useEffect(() => {
    if (inView && hasNextPage && online) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (!online && hasNextPage)
    return <OfflineFetchMessage fetchMore={fetchMore} online={online} />;

  return (
    <div ref={ref} className="flex place-content-center">
      {hasNextPage ? (
        <Refresh className="animate-spin" />
      ) : (
        <p>{t('common.noMoreResults')}</p>
      )}
    </div>
  );
};

export default LoadMore;

import { gql, useMutation, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast, TypeOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { EyeOff, ExclamationCircle } from 'heroicons-react';
import { useNetworkState } from 'react-use';
import React, { useState } from 'react';
import {
  DescriptionList,
  DescriptionListItem,
} from 'modules/common/components/DescriptionList';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import Input from 'modules/common/components/Input';
import {
  StockCountFragment,
  StockCountProduct,
} from 'modules/inventory/components/StockCountList';
import Button from 'modules/common/components/Button';
import { stockTakeFragment } from 'modules/inventory/components/StockTakesList';
import { useSession } from 'hooks/useSession';
import useStockCountDraft from 'hooks/useStockCountDraft';
import ConfirmModal from 'modules/common/components/ConfirmModal';

interface StockCountParams {
  stockCountId: number;
  stockLocationId: number;
  stockTakeId: number;
}

export interface StockCountWrapper {
  quantity: number;
  expectedQuantity: number;
  product: StockCountProduct;
  stockLevel: {
    position: number;
  };
  id: string;
}

export interface StockLocationWrapper {
  id: number;
  name: string;
  stockTake: {
    id: number;
    blind: boolean;
    closedAt: string;
    periodEndingOn: string;
    stockCount: StockCountWrapper;
  };
}

interface StockCountData {
  holder: {
    id: string;
    stockLocation: StockLocationWrapper;
  };
}

export const UPDATE_STOCK_COUNT_MUTATION = gql`
  mutation UpdateStockCountMutation($id: Int!, $quantity: Float!) {
    updateStockCount(input: { id: $id, quantity: $quantity }) {
      stockCount {
        id
        quantity
      }
    }
  }
`;

const StockCount: React.FC<StockCountParams> = ({
  stockCountId,
  stockTakeId,
  stockLocationId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { online } = useNetworkState();
  const [notSaved, setNotSaved] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [action, setAction] = useState('close');
  const { holder } = useSession();
  const {
    getUpdatedStockCountValue,
    saveStockCountDraft,
    deleteDraftStockTake,
  } = useStockCountDraft();

  const offlineUpdatedValue = getUpdatedStockCountValue(
    stockTakeId,
    stockCountId
  );

  const notify = (message: string, toastType: TypeOptions) =>
    toast(message, { type: toastType, toastId: 'stockCountToast' });

  const handleSuccess = () => {
    deleteDraftStockTake(stockTakeId, [stockCountId]);
    notify(t('common.successMessage'), 'info');
    action === 'scan'
      ? history.push(
          `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}/scan_barcode`
        )
      : history.push({
          pathname: `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}`,
          state: {
            stockCountId: stockCountId,
          },
        });
  };

  const handleOffline = (action: string) => {
    const updatedStockCountData = {
      quantity: parseFloat(getValues('count')),
      updatedAt: new Date(),
      stockCountId: stockCountId,
      productName:
        product.concatenatedBrand +
        (product.concatenatedBrand ? ': ' : '') +
        product.concatenatedDescription,
    };
    const concatenatedStockTakeName = `${stockLocationName} : ${month}`;

    saveStockCountDraft(
      updatedStockCountData,
      stockTakeId,
      stockLocationId,
      concatenatedStockTakeName
    );
    notify(t('inventory.stockCount.updateOfflineError'), 'info');
    action === 'scan'
      ? history.push(
          `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}/scan_barcode`
        )
      : history.push(
          `/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}`
        );
  };

  const handleError = () => {
    setNotSaved(true);
  };

  const [updateCount, { loading: mutationLoading }] = useMutation(
    UPDATE_STOCK_COUNT_MUTATION,
    {
      onCompleted: handleSuccess,
      onError: handleError,
    }
  );

  const STOCK_COUNT_QUERY = gql`
    query StockCountQuery(
      $stockLocationId: Int!
      $stockTakeId: Int!
      $id: Int!,
      $holderId: Int
    ) {
      holder(id: $holderId) {
        id
        stockLocation(id: $stockLocationId) {
          id
          name
          stockTake(id: $stockTakeId) {
            ...stockTakeNode
            stockCount(id: $id) {
              ...stockCountNode
            }
          }
        }
      }
    }
  ${StockCountFragment}, ${stockTakeFragment}`;

  const { loading, error, data, refetch } = useQuery<StockCountData>(
    STOCK_COUNT_QUERY,
    {
      variables: {
        stockLocationId: stockLocationId,
        stockTakeId: stockTakeId,
        id: stockCountId,
        holderId: holder?.id,
      },
      fetchPolicy: online ? 'network-only' : 'cache-first',
      context: {
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
      },
    }
  );
  if (error) return <ErrorMessage error={error} retry={refetch} />;
  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const onSubmit = (action: string) => {
    action === 'scan' && setAction('scan');
    online
      ? updateCount({
          variables: {
            id: stockCountId,
            quantity: parseFloat(getValues('count')),
          },
        })
      : handleOffline(action);
  };
  const { stockLocation } = data.holder;
  let { name: stockLocationName } = stockLocation;
  const {
    stockCount,
    blind,
    closedAt,
    periodEndingOn,
  } = stockLocation.stockTake;
  const { product } = stockCount;

  const month = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(periodEndingOn));

  const subTitle = `${stockLocation.name}: ${month}`;

  const getSaveButton = (type: string) => {
    let label = '';
    const moveToScan = !!(type === 'scan');
    switch (type) {
      case 'scan':
        label = t('common.saveAndScanMore');
        break;
      case 'close':
        label = t('common.saveAndClose');
        break;
    }
    return (
      <Button
        disabled={mutationLoading || (closedAt ? true : false)}
        variant="rounded"
        primaryBorder={moveToScan}
        color={moveToScan ? 'default' : 'primary'}
        handleClick={handleSubmit(() =>
          onSubmit(moveToScan ? 'scan' : 'close')
        )}
        loading={type === action && mutationLoading}
        customWidth={'w-32'}
        loaderColor={moveToScan ? 'text-blue' : 'text-white'}
      >
        <p>{label}</p>
      </Button>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {t('inventory.stockCount.pageTitle')} | {t('common.siteTitle')}
        </title>
      </Helmet>
      <div>
        <h1 className="text-center font-bold">
          {t('inventory.stockCount.pageTitle')}
        </h1>
        <h2 className="text-xs text-gray text-center">{subTitle}</h2>
      </div>
      {offlineUpdatedValue && (
        <div className="flex justify-between items-center bg-red-500 text-white text-sm px-4 py-3 text-center">
          <div className="flex items-center font-bold">
            <ExclamationCircle className="color-red-100 mr-2" />
            <p>{t('inventory.drafts.unsavedcount')}</p>
          </div>
          <p
            className="underline cursor-pointer"
            onClick={() => setShowWarning(true)}
          >
            {t('common.discard')}
          </p>
        </div>
      )}
      <form onSubmit={handleSubmit(() => onSubmit(action))}>
        <DescriptionList>
          <DescriptionListItem label={t('inventory.stockCount.position')}>
            {stockCount.stockLevel.position}
          </DescriptionListItem>
          <DescriptionListItem label={t('inventory.stockCount.product')}>
            {product.concatenatedBrand && `${product.concatenatedBrand}: `}
            {product.concatenatedDescription}
          </DescriptionListItem>
          <DescriptionListItem label={t('inventory.stockCount.count')}>
            <div className="w-48" key={offlineUpdatedValue}>
              <Input
                required
                type="number"
                min={-1000000}
                max={1000000}
                name="count"
                placeholder={t('inventory.stockCount.enterQuantity')}
                errors={errors.count}
                register={register}
                defaultValue={
                  offlineUpdatedValue
                    ? offlineUpdatedValue
                    : stockCount.quantity
                }
                inputFieldStyle={`${notSaved ? 'border-red-500' : ''}`}
                inputBoxStyle="text-center"
                readOnly={closedAt ? true : false}
                autoFocus
              />
            </div>
          </DescriptionListItem>
          <DescriptionListItem label={t('inventory.stockCount.countBy')}>
            <p className="text-gray-700">{product.itemPackName}</p>
          </DescriptionListItem>
          <DescriptionListItem label={t('inventory.stockCount.expected')}>
            {blind ? (
              <div className="flex flex-row-reverse md:flex-row">
                <EyeOff />
              </div>
            ) : (
              <p className="text-gray-700">{stockCount.expectedQuantity}</p>
            )}
          </DescriptionListItem>
        </DescriptionList>
        <div className="py-6 px-4 items-center justify-end flex">
          <Link
            to={`/inventory/stock_locations/${stockLocationId}/stock_takes/${stockTakeId}`}
            className="bg-white text-gray-500 py-4 rounded-full mr-2 md:mr-5"
          >
            {t('common.cancel')}
          </Link>
          {getSaveButton('scan')}
          <span className="ml-2 md:ml-5">{getSaveButton('close')}</span>
        </div>
      </form>
      {showWarning && (
        <ConfirmModal
          show={showWarning}
          toggleModal={(value: boolean) => setShowWarning(value)}
          warningMessage={t('inventory.drafts.deleteWarning')}
          confirmMessage={t('common.confirm')}
          handleConfirm={() =>
            deleteDraftStockTake(stockTakeId, [stockCountId])
          }
        />
      )}
    </>
  );
};

export default StockCount;

import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import Login from 'modules/access/components/Login';
import Dashboard from 'modules/purchasing/pages/Dashboard';
import StockTake from 'modules/inventory/pages/StockTakePage';
import StockLocation from 'modules/inventory/pages/StockLocationPage';
import StockLocations from 'modules/inventory/pages/StockLocationsPage';
import BarcodeScannerPage from 'modules/inventory/pages/BarcodeScannerPage';
import OrganisationSwitcher from 'modules/access/components/OrganisationSwitcher';
import DraftStockTakesPage from 'modules/inventory/pages/DraftStockTakesPage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/inventory/stock_locations/:stockLocationId/stock_takes/:stockTakeId/scan_barcode">
        <BarcodeScannerPage />
      </PrivateRoute>
      <PrivateRoute path="/inventory/stock_locations/:stockLocationId/stock_takes/:id/stock_count/:stockCountId">
        <StockTake />
      </PrivateRoute>
      <PrivateRoute path="/inventory/stock_locations/:stockLocationId/stock_takes/:id">
        <StockTake />
      </PrivateRoute>
      <PrivateRoute path="/inventory/stock_locations/:id">
        <StockLocation />
      </PrivateRoute>
      <PrivateRoute path="/inventory/stock_locations">
        <StockLocations />
      </PrivateRoute>
      <PrivateRoute path="/organisations">
        <OrganisationSwitcher />
      </PrivateRoute>
      <PrivateRoute path="/draft_stock_takes">
        <DraftStockTakesPage />
      </PrivateRoute>
      <PrivateRoute path="/">
        <Dashboard />
      </PrivateRoute>
    </Switch>
  );
}

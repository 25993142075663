/* eslint-disable @typescript-eslint/no-explicit-any */
import { chain, isString } from 'lodash';
import {
  StockCountData,
  StockTakes,
} from 'modules/inventory/components/StockTakesList';

export default function sortArray(
  sortArgument: any,
  array: StockCountData | StockTakes
) {
  const sortBy: string = sortArgument.property;
  const sortedArr = chain(array.edges)
    .toPairs()
    .map(([key, ...obj]) => {
      let sortVal = sortArgument.property
        .split('_')
        .reduce((a: string, b: number) => a[b], obj[0]?.node);
      if (isString(sortVal)) {
        // added logic to remove special char and replace multiple spaces with single space and then sort eg. https://stocktake.uat.purchaseplus.com/inventory/stock_locations/71
        //TODO : result may differ from server sorting for edge cases non-english char eg. https://stocktake.uat.purchaseplus.com/inventory/stock_locations/488
        sortVal = sortVal.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s\s+/g, ' ');
      }
      return {
        ...obj[0],
        [sortBy]: sortVal,
      };
    })
    .orderBy(sortBy, sortArgument.direction.toLowerCase())
    .map(({ sortBy, ...obj }) => obj)
    .value();
  return sortedArr;
}

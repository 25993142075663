import React from 'react';
import { Transition } from '@headlessui/react';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';

export interface BottomDrawerProps extends React.HTMLProps<HTMLDivElement> {
  show: boolean;
  drawerTitle?: string;
  setDrawerScreenVisible: Function;
  children: React.ReactNode;
  height?: number;
  minDrawerHeight?: number;
}

export const BottomDrawer: React.FC<BottomDrawerProps> = ({
  show,
  drawerTitle,
  setDrawerScreenVisible,
  height = 400,
  minDrawerHeight = 300,
  children,
}) => {
  const resizeStop = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    if (data.size.height < minDrawerHeight) {
      setDrawerScreenVisible(false);
    }
  };

  return (
    <Transition
      show={show}
      enter="ease-in-out duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in-out duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <RemoveScrollBar />
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-auto z-40`}
        aria-hidden="true"
        onClick={() => setDrawerScreenVisible(false)}
      ></div>
      <ResizableBox
        width={Infinity}
        height={height}
        minConstraints={[Infinity, 100]}
        maxConstraints={[Infinity, Infinity]}
        axis="y"
        resizeHandles={['n']}
        onResizeStop={resizeStop}
        handle={
          <div className="flex justify-center py-2.5 absolute top-0 cursor-ns-resize w-full">
            <div className="w-20 border-2 border-gray-400 bg-gray-400"></div>
          </div>
        }
        className="fixed inset-x-0 bottom-0 pt-5 rounded-t-3xl max-w-full bg-white overflow-y-hidden max-h-full z-50"
      >
        <>
          {drawerTitle && (
            <div className={`py-8 flex w-full justify-center bottom-0`}>
              <p className="font-bold text-xl">{drawerTitle}</p>
            </div>
          )}
          {children}
        </>
      </ResizableBox>
    </Transition>
  );
};

export default BottomDrawer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircle } from 'heroicons-react';
import { DraftStockTake } from 'hooks/useStockCountDraft';

export interface SyncErrorProps {
  stockTakesDraft: DraftStockTake[];
  displayStockTakeLbl?: boolean;
}

const SyncError: React.FC<SyncErrorProps> = ({
  stockTakesDraft,
  displayStockTakeLbl = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-red-500 mt-4 text-white text-sm font-bold py-3 mb-5 px-2">
      <div className="flex">
        <ExclamationCircle className="color-red-100 mr-2" />
        <p>{t('inventory.drafts.failedUpload')}</p>
      </div>
      <div>
        {stockTakesDraft?.map((item, index) => (
          <div key={index}>
            {displayStockTakeLbl && <p>{item.name}</p>}
            <ol className="list-disc px-6">
              {item.stockCounts.map((stockCount) => (
                <li key={stockCount.stockCountId} className="font-medium">
                  {stockCount?.productName}
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SyncError;

import { gql, useLazyQuery } from '@apollo/client';
import { TRelayPageInfo } from '@apollo/client/utilities/policies/pagination';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import LoadMore from 'modules/common/components/LoadMore';
import { RansackFilter } from 'hooks/useRansackFilter';
import { useEffect } from 'react';
import { RansackSort } from 'hooks/useRansackSort';
import { StockLocationCard } from '../StockLocationCard';

export const STOCK_LOCATIONS_QUERY = gql`
  query StockLocationsQuery(
    $first: Int
    $after: String
    $filter: RansackFilterType
    $sort: [RansackSortType!]
    $holderId: Int
  ) {
    holder(id: $holderId) {
      id
      stockLocations(
        first: $first
        after: $after
        filter: $filter
        sort: $sort
      ) {
        edges {
          cursor
          node {
            id
            name
            balanceValue
            stockTakeStatus
            lastStockTake {
              closable
              periodEndingOn
              closedAt
              id
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export interface StockLocation {
  id: number;
  name: string;
  balanceValue: number;
  stockTakeStatus: string;
  lastStockTake: LastStockTakeData;
}

export interface LastStockTakeData {
  closable: boolean;
  periodEndingOn: string;
  closedAt: string;
  id: number;
}

export interface StockLocationsData {
  holder: {
    stockLocations: {
      edges: Array<{ node: StockLocation }>;
      pageInfo: TRelayPageInfo;
    };
  };
}

export interface StockLocationsListProps {
  first?: number;
  loadMore?: boolean;
  filter?: RansackFilter;
  sort?: RansackSort;
  holderId?: number;
}

const StockLocationsList: React.FC<StockLocationsListProps> = ({
  first = 25,
  loadMore = false,
  sort,
  filter,
  holderId,
}) => {
  const { t } = useTranslation();
  const { online } = useNetworkState();

  const loadMoreStockLocations = async (pageInfo: TRelayPageInfo) => {
    if (pageInfo?.hasNextPage && fetchMore) {
      const res = await fetchMore({
        variables: {
          first: 25,
          after: pageInfo?.endCursor,
        },
      });
      const nextPageInfo = res.data?.holder?.stockLocations?.pageInfo;
      if (nextPageInfo.hasNextPage) {
        loadMoreStockLocations(nextPageInfo);
      }
    }
  };

  const [
    getStockLocations,
    { loading, error, data, fetchMore },
  ] = useLazyQuery<StockLocationsData>(STOCK_LOCATIONS_QUERY, {
    variables: {
      first,
      sort: sort && {
        property: sort.property,
        direction: sort.direction,
      },
      filter,
      holderId,
      isAvailableOffline: true,
    },
    onCompleted: (data) =>
      loadMoreStockLocations(data?.holder?.stockLocations?.pageInfo),
    fetchPolicy: online ? 'cache-and-network' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
  });

  // Initial load
  useEffect(() => {
    getStockLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (error) return <ErrorMessage error={error} retry={fetchMore} />;
  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const { stockLocations } = data.holder;

  return (
    <>
      {stockLocations.edges.map(({ node }) => (
        <StockLocationCard key={node.id} node={node} />
      ))}
      {loadMore && (
        <LoadMore
          fetchMore={() => {
            fetchMore &&
              fetchMore({
                variables: {
                  after: stockLocations.pageInfo.endCursor,
                },
              });
          }}
          hasNextPage={stockLocations.pageInfo.hasNextPage}
        />
      )}
    </>
  );
};
export default StockLocationsList;

import React from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Organisation, User } from 'hooks/useSession';
import { OfficeBuildingOutline, StatusOfflineOutline } from 'heroicons-react';
import { ReactComponent as DashboardImg } from 'assets/img/Dashboard.svg';
import { ReactComponent as InventoryImg } from 'assets/img/Inventory.svg';
import { UserAvatar } from '../UserAvatar';
import {
  NavigationItemProps,
  NavigationSidebarItem,
} from '../NavigationSidebarItem';

export interface NavigationSidebarProps {
  user: User;
  organisation: Organisation;
  setWarning: Function;
  draftStockCountLength: number;
}

export const NavigationSidebar: React.FC<NavigationSidebarProps> = ({
  user,
  organisation,
  setWarning,
  draftStockCountLength,
}) => {
  const { t } = useTranslation();

  const navigationItemArray: Array<NavigationItemProps> = [
    {
      title: t('common.dashboard'),
      image: <DashboardImg />,
      path: '/',
      key: 'dashboard',
    },
    {
      title: t('common.inventory'),
      subTitle: t('common.stockLocations'),
      image: <InventoryImg />,
      path: '/inventory/stock_locations',
      key: 'inventory',
    },
    {
      title: t('access.organisationSwitcher.switchOrganisation'),
      image: <OfficeBuildingOutline className="text-blue-500" />,
      path: '/organisations',
      key: 'switchOrganisation',
    },
    {
      title: `${t(
        'inventory.drafts.unsavedStockCounts'
      )} (${draftStockCountLength})`,
      image: <StatusOfflineOutline className="text-blue-500" />,
      path: '/draft_stock_takes',
      key: 'draftStockCounts',
    },
  ];

  const handleSignOutClick = () => {
    setWarning(true);
  };

  if (!user) return <p>{t('common.loading')}</p>;

  const { name, isAdmin } = organisation;
  return (
    <section className="p-6 pb-0 pt-5 md:pl-8 flex flex-col h-full">
      <div className="flex-col overflow-auto">
        <UserAvatar user={user} />
        <h1 className="text-3xl mt-5 font-bold text-gray-900">
          {user.full_name}
        </h1>
        <h4 className="text-l font-semibold text-gray-900">{name}</h4>
        <h4 className="text-medium text-gray-900">{user.email}</h4>
        {map(navigationItemArray, (item) => {
          if (
            (!item.adminOnly || isAdmin) &&
            (organisation.isInventoryEnabled || item.key !== 'inventory')
          )
            return <NavigationSidebarItem key={item.key} item={item} />;
        })}
      </div>
      <div className="mt-auto pt-10 pb-2 flex">
        <button className="font-semibold" onClick={handleSignOutClick}>
          {t('common.signOut')}
        </button>
      </div>
    </section>
  );
};

export default NavigationSidebar;

import { Dispatch, SetStateAction } from 'react';
import { Transition } from '@headlessui/react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { useTranslation } from 'react-i18next';
import { RansackSort, sortProperty, SortProperty } from 'hooks/useRansackSort';
import { ReactComponent as SortAsc } from 'assets/img/SortAsc.svg';
import { ReactComponent as SortDesc } from 'assets/img/SortDesc.svg';

interface SortMenuProps {
  activeRansackSort: RansackSort | undefined;
  setSortByScreenVisibile: Dispatch<SetStateAction<boolean>>;
  setRansackSort: Dispatch<SetStateAction<RansackSort | undefined>>;
  sortByScreenVisibile: boolean;
  sortOptions: SortProperty[];
  onSortingChange?: Function;
}

const SortMenu: React.FC<SortMenuProps> = ({
  activeRansackSort,
  setRansackSort,
  setSortByScreenVisibile,
  sortByScreenVisibile,
  sortOptions,
  onSortingChange,
}) => {
  const { t } = useTranslation();

  let activeSort = undefined;
  if (activeRansackSort) {
    activeSort = sortProperty(activeRansackSort);
  }

  const handleSelect = (item: RansackSort) => {
    setRansackSort(item);
    setSortByScreenVisibile(false);
    onSortingChange && onSortingChange(item);
  };

  const resizeStop = (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    if (data.size.height < 300) {
      setSortByScreenVisibile(false);
    }
  };

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => setSortByScreenVisibile(true)}
      >
        <div className="flex">
          <p className="font-semibold text-sm text-gray-700">
            {t('common.sortBy')}
          </p>
          {activeSort && (
            <div className="flex">
              <p className="capitalize ml-2 font-semibold text-sm text-blue">
                {t(activeSort.label)}
              </p>
              {activeSort.direction === 'ASC' ? (
                <SortAsc className="w-5 h-5" />
              ) : (
                <SortDesc className="w-5 h-5" />
              )}
            </div>
          )}
        </div>
      </div>
      <Transition
        show={sortByScreenVisibile}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity overflow-auto z-20`}
          aria-hidden="true"
          onClick={() => setSortByScreenVisibile(false)}
        ></div>
        <ResizableBox
          width={Infinity}
          height={400}
          minConstraints={[Infinity, 100]}
          maxConstraints={[Infinity, Infinity]}
          axis="y"
          resizeHandles={['n']}
          onResizeStop={resizeStop}
          handle={
            <div className="flex justify-center py-2.5 absolute top-0 cursor-ns-resize w-full">
              <div className="w-20 border-2 border-gray-400 bg-gray-400"></div>
            </div>
          }
          className="absolute inset-x-0 rounded-t-3xl bottom-0 max-w-full bg-white overflow-y-hidden max-h-full z-20"
        >
          <>
            <div className={`py-8 flex w-full justify-center bottom-0`}>
              <p className="font-bold text-2xl">{t('common.sortBy')}</p>
            </div>
            <div className="h-4/5 overflow-auto pb-5">
              {sortOptions.map((item) => {
                return (
                  <div
                    onClick={() => handleSelect(item)}
                    className="py-5 text-xl cursor-pointer mx-5 border-b-2 border-gray-200"
                    key={item.key}
                  >
                    {t(item.title)}
                  </div>
                );
              })}
            </div>
          </>
        </ResizableBox>
      </Transition>
    </div>
  );
};

export default SortMenu;

import { StockCountEdgeNode, StockCountProduct } from '../StockCountList';

interface QuantityValueIndicatorProps {
  quantity: StockCountEdgeNode['quantity'];
  itemPackName: StockCountProduct['itemPackName'];
  offlineUpdatedValue?: number;
}

export const QuantityValueIndicator: React.FC<QuantityValueIndicatorProps> = ({
  quantity,
  itemPackName,
  offlineUpdatedValue,
}) => {
  return (
    <div
      className={`w-20 h-20 flex flex-col items-center justify-center rounded-3xl ${
        offlineUpdatedValue ? 'bg-gray-300' : 'bg-aqua'
      }`}
    >
      <p className="font-bold text-sm text-aqua-800">
        {offlineUpdatedValue ? `${offlineUpdatedValue}*` : quantity ?? '-'}
      </p>
      <p className="font-medium text-gray-800">{itemPackName}</p>
    </div>
  );
};

import { createCtx } from 'hooks/createCtx';
import { useAuth } from 'hooks/useAuth';
import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { useState } from 'react';

export interface Organisation {
  id: number;
  name: string;
  isAdmin: boolean;
  isInventoryEnabled: boolean;
}

export interface SwitchOrganisation extends Organisation {
  parentId: number;
  parent: SwitchOrganisation;
  isAPurchaser: boolean;
  legalEntity: boolean;
  children?: Array<SwitchOrganisation>;
}
export interface User {
  id: number;
  full_name: string;
  email: string;
  default_organisation_id: number;
  changedOrganisation: number;
}

export interface Holder {
  id: number;
}

interface SessionContext {
  organisation: Organisation;
  holder: Holder;
  deleteSession: Function;
}

const [useSession, SessionContextProvider] = createCtx<SessionContext>();

export const SessionProvider: React.FC = ({ children }) => {
  const session = useProvideSession();

  return (
    <SessionContextProvider value={session}>{children}</SessionContextProvider>
  );
};

export { useSession };

const ORGANISATION_QUERY = gql`
  query organisationQuery($id: Int) {
    organisation(id: $id) {
      id
      name
      isInventoryEnabled
      isAdmin: isAAdministrator
      parent {
        id
        name
      }
    }
  }
`;

const fetchOrg = async (user: User, client: ApolloClient<object>) => {
  const result = await client.query({
    query: ORGANISATION_QUERY,
    variables: {
      id:
        (user as User).changedOrganisation ||
        (user as User).default_organisation_id,
    },
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/access/api/graphql`,
    },
  });

  return result;
};

const HOLDER_QUERY = gql`
  query holderQuery($organisationId: Int!) {
    legalHolder(organisationId: $organisationId) {
      id
    }
  }
`;

const fetchHolder = async (
  organisation: Organisation,
  client: ApolloClient<object>
) => {
  const result = await client
    .query({
      query: HOLDER_QUERY,
      variables: {
        organisationId: (organisation as Organisation).id,
      },
      context: {
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
      },
    })
    .catch(() => {
      return { data: {} };
    });

  return result;
};

export function useProvideSession() {
  let { user, authHeaders } = useAuth();

  const [organisation, setOrganisation] = useState({} as Organisation);
  const [holder, setHolder] = useState({} as Holder);

  const client = useApolloClient();

  const deleteSession = () => {
    setOrganisation({} as Organisation);
    setHolder({} as Holder);
  };

  if (!user.id || !Object.keys(authHeaders).length) {
    return {
      organisation,
      holder,
      deleteSession,
    };
  }

  fetchOrg(user, client).then((result) => {
    setOrganisation(result.data.organisation);
  });

  if (!organisation.id) {
    return {
      organisation,
      holder,
      deleteSession,
    };
  }

  if (organisation.isInventoryEnabled) {
    fetchHolder(organisation, client).then((result) => {
      setHolder(result.data.legalHolder);
    });
  }

  return {
    organisation,
    holder,
    deleteSession,
  };
}

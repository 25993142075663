import { ReactiveVar } from '@apollo/client/core';
import { RansackFilter } from 'hooks/useRansackFilter';
import { isEqual } from 'lodash';

export interface WrappedFilter {
  identifier: unknown;
  filter: RansackFilter;
}

export function usePreservedFilter(
  preservedFilter: ReactiveVar<WrappedFilter[]>,
  identifier: unknown
) {
  const _preservedFilter = preservedFilter();
  const handleFilterChange = (filter: RansackFilter) => {
    const wrappedFilter = {
      identifier,
      filter,
    };

    const existingFilter = _preservedFilter.find((x) =>
      isEqual(x.identifier, identifier)
    );

    if (existingFilter) {
      existingFilter.filter = wrappedFilter.filter;
    } else {
      preservedFilter([..._preservedFilter, wrappedFilter]);
    }
  };

  const activeFilter = () =>
    preservedFilter().find((x) => isEqual(x.identifier, identifier))?.filter;

  return {
    handleFilterChange,
    activeFilter,
  };
}

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { TRelayPageInfo } from '@apollo/client/utilities/policies/pagination';
import { RansackFilter } from 'hooks/useRansackFilter';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import LoadMore from 'modules/common/components/LoadMore';
import { RansackSort } from 'hooks/useRansackSort';
import useLocalStorage from 'hooks/useLocalStorage';
import BottomDrawer from 'modules/common/components/BottomDrawer';
import StockCount from 'modules/inventory/components/StockCount';
import { preservedFilters } from 'apollo/ApolloProviderWrapper';
import { StockCountCard } from '../StockCountCard';

export interface StockCountListProps {
  stockLocationId: number;
  id: number;
  filter?: RansackFilter;
  sort?: RansackSort;
  online?: boolean;
  lastSyncDate?: string;
  holderId?: number;
  getUpdatedStockCountValue: (
    stockTakeId: number,
    stockCountId: number
  ) => number | undefined;
  stockCountId?: number;
  syncCompleted: boolean;
  resetList: Function;
}

export interface StockTakeType {
  holder: {
    id: number;
    location: {
      id: number;
      barcode: {
        id: number;
        stockCount: {
          id: number;
        };
      };
    };
    stockLocation: {
      name: string;
      id: number;
      customSort?: boolean;
      stockTake: {
        id: number;
        periodEndingOn: Date;
        closedAt: Date;
        blind?: boolean;
        partial?: boolean;
        stockCounts: {
          edges: Array<StockCountEdge>;
          pageInfo: TRelayPageInfo;
        };
      };
    };
  };
}

export interface StockCountEdge {
  node: StockCountEdgeNode;
  cursor?: string;
  sortBy?: string;
}

export interface StockCountEdgeNode {
  __ref: string;
  id: number;
  quantity: number;
  stockLevel: {
    position: number;
  };
  product: StockCountProduct;
  linkedProducts: [StockCountProduct];
}

export interface StockCountProduct {
  id: number;
  itemPackName: string;
  concatenatedDescription: string;
  concatenatedBrand: string;
  barcodes: {
    edges: Array<BarcodeEdge>;
  };
}

interface BarcodeEdge {
  node: BarcodeEdgeNode;
}
export interface BarcodeEdgeNode {
  id: number;
  body: string;
}

export interface BarcodeStockCountMapper extends BarcodeEdgeNode {
  stockCountId: number;
  stockTakeId: number;
}

export const StockCountFragment = gql`
  fragment stockCountNode on StockCount {
    id
    quantity
    expectedQuantity
    updatedAt
    stockLevel {
      position
    }
    product {
      id
      itemPackName
      concatenatedBrand
      concatenatedDescription
      barcodes {
        edges {
          node {
            id
            body
          }
        }
      }
    }
    linkedProducts {
      id
      itemPackName
      concatenatedBrand
      concatenatedDescription
      barcodes {
        edges {
          node {
            id
            body
          }
        }
      }
    }
  }
`;

export const STOCK_TAKE_QUERY = gql`
  query StockTakeQuery(
    $stockLocationId: Int!
    $id: Int!
    $first: Int
    $after: String
    $sort: [RansackSortType!]
    $filter: RansackFilterType
    $holderId: Int
    $barcodeBody: String!
  ) {
    holder(id: $holderId) {
      id
      location(id: $stockLocationId) {
        id
        barcode(body: $barcodeBody) {
          id
          stockCount(stockTakeId: $id) {
            id
          }
        }
      }
      stockLocation(id: $stockLocationId) {
        name
        id
        stockTake(id: $id) {
          id
          periodEndingOn
          closedAt
          stockCounts(
            first: $first
            after: $after
            sort: $sort
            filter: $filter
          ) {
            edges {
              node {
                ...stockCountNode
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
  ${StockCountFragment}
`;

const StockCountList: React.FC<StockCountListProps> = ({
  stockLocationId,
  id,
  sort,
  filter = {},
  online,
  lastSyncDate,
  holderId,
  syncCompleted,
  getUpdatedStockCountValue,
  stockCountId,
  resetList,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const [stockCountSyncDate, setStockCountSyncDate] = useLocalStorage(
    'stockCountsSyncDate',
    {}
  );

  const [syncBarcodes, setSyncBarcodes] = useLocalStorage<
    Array<BarcodeStockCountMapper>
  >('syncBarcodes', []);

  const fetchUpdatedStockCountsData = async (
    lastSyncDate: string,
    endCursor?: string
  ) => {
    if (fetchMore) {
      const res = await fetchMore({
        variables: {
          first: 25,
          after: endCursor,
          isAvailableOffline: true,
          filter: {
            q: [
              {
                property: 'updatedAt',
                matcher: 'GTEQ',
                value: lastSyncDate,
              },
            ],
          },
        },
      });

      const pageInfo =
        res.data?.holder?.stockLocation?.stockTake?.stockCounts?.pageInfo;

      if (pageInfo.hasNextPage) {
        fetchUpdatedStockCountsData(lastSyncDate, pageInfo.endCursor);
      } else {
        const lastSynced: { [key: number]: number } = stockCountSyncDate;
        Object.assign(lastSynced, { [id]: new Date().toISOString() });
        setStockCountSyncDate(lastSynced);
      }
    }
  };

  const [
    getStockTake,
    { loading, error, data, fetchMore },
  ] = useLazyQuery<StockTakeType>(STOCK_TAKE_QUERY, {
    variables: {
      stockLocationId,
      id,
      first: 25,
      filter,
      barcodeBody: online ? filter?.q?.[0]?.value || '' : '',
      isAvailableOffline: !!lastSyncDate,
      sort: {
        property: sort?.property,
        direction: sort?.direction,
      },
      holderId,
    },
    onCompleted: (data) => {
      if (!data?.holder?.stockLocation?.stockTake?.closedAt && lastSyncDate) {
        fetchUpdatedStockCountsData(lastSyncDate);
      }
      generateBarcodeMap();
      searchBarcode();
    },
    fetchPolicy: online
      ? lastSyncDate
        ? 'cache-and-network'
        : 'network-only'
      : 'cache-first',
    nextFetchPolicy: 'cache-first',
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
  });

  const generateBarcodeMap = () => {
    let barcodes: Array<BarcodeStockCountMapper> =
      syncBarcodes?.[0]?.stockTakeId === id ? syncBarcodes : [];

    data?.holder?.stockLocation?.stockTake?.stockCounts?.edges?.forEach(
      ({ node }: StockCountEdge) => {
        const stockCountNodePresent = barcodes.find(
          (x) => x.stockCountId === node.id
        );
        if (!stockCountNodePresent) {
          // Searchable barcode based on the product ID.
          barcodes.push({
            id: 0,
            body: node.product.id.toString(),
            stockCountId: node.id,
            stockTakeId: id,
          });

          // Searchable barcodes added manually by user
          node?.product?.barcodes?.edges?.forEach((barcode: BarcodeEdge) => {
            barcodes.push({
              ...barcode.node,
              stockCountId: node.id,
              stockTakeId: id,
            });
          });

          // Do the same for linkedProducts
          node?.linkedProducts?.forEach((product: StockCountProduct) => {
            barcodes.push({
              id: 0,
              body: product.id.toString(),
              stockCountId: node.id,
              stockTakeId: id,
            });

            node?.product?.barcodes?.edges?.forEach((barcode: BarcodeEdge) => {
              barcodes.push({
                ...barcode.node,
                stockCountId: node.id,
                stockTakeId: id,
              });
            });
          });
        }
      }
    );

    setSyncBarcodes(barcodes);
  };

  const searchBarcode = () => {
    if (!online) {
      syncBarcodes?.forEach((barcode) => {
        if (barcode.body === filter?.q?.[0]?.value) {
          removeCurrentFilter();
          resetList();

          history.push(
            `/inventory/stock_locations/${stockLocationId}/stock_takes/${id}/stock_count/${barcode.stockCountId}`
          );
        }
      });
    } else if (data?.holder?.location?.barcode?.stockCount) {
      removeCurrentFilter();
      resetList();

      history.push(
        `/inventory/stock_locations/${stockLocationId}/stock_takes/${id}/stock_count/${data?.holder?.location?.barcode?.stockCount?.id}`
      );
    }
  };

  const removeCurrentFilter = () => {
    const preserveFilter = preservedFilters()?.filter(
      (x) =>
        !isEqual(x.identifier, {
          stockLocationId: stockLocationId.toString(),
          id: id.toString(),
        })
    );

    preservedFilters(preserveFilter);
  };

  const readApolloFragment = (stockCountId: number) => {
    const read_query = gql`
      fragment WriteStockCount on StockCount {
        id
        quantity
      }
    `;

    return client.readFragment({
      id: 'StockCount:' + stockCountId,
      fragment: read_query,
    });
  };

  useEffect(() => {
    lastSyncDate && getStockTake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockCountId]);

  useEffect(() => {
    getStockTake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSyncDate && syncCompleted)
      fetchUpdatedStockCountsData(lastSyncDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncCompleted]);

  useEffect(() => {
    generateBarcodeMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.holder?.stockLocation?.stockTake?.stockCounts]);

  if (error) return <ErrorMessage error={error} retry={fetchMore} />;
  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const { stockTake } = data.holder.stockLocation;

  const handleClose = () => {
    history.push(
      `/inventory/stock_locations/${stockLocationId}/stock_takes/${id}`
    );
  };

  return (
    <>
      {stockTake.stockCounts.edges.map((stockCount: StockCountEdge) => {
        let { node } = stockCount;
        const offlineVal = getUpdatedStockCountValue(id, stockCount.node.id);

        if (!offlineVal && syncCompleted) {
          const stockCountQuantity = readApolloFragment(stockCount.node.id);
          node = { ...node, quantity: stockCountQuantity.quantity };
        }

        return (
          <div key={stockCount.node.id}>
            <StockCountCard
              stockCount={node}
              stockLocationId={stockLocationId}
              stockTakeId={id}
              key={node.id}
              position={node.stockLevel.position}
              offlineUpdatedValue={offlineVal}
            />
          </div>
        );
      })}
      {fetchMore && (
        <LoadMore
          fetchMore={() => {
            fetchMore({
              variables: {
                isAvailableOffline: !!lastSyncDate,
                after: stockTake.stockCounts.pageInfo.endCursor,
              },
            });
          }}
          hasNextPage={stockTake.stockCounts.pageInfo.hasNextPage}
        />
      )}
      {stockCountId && (
        <BottomDrawer
          show={true}
          setDrawerScreenVisible={handleClose}
          height={600}
          minDrawerHeight={300}
        >
          <StockCount
            stockCountId={stockCountId}
            stockLocationId={stockLocationId}
            stockTakeId={id}
          />
        </BottomDrawer>
      )}
    </>
  );
};

export default StockCountList;

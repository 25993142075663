import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { flatListToTree } from 'hooks/flatListToTree';
import {
  Context,
  SwitcherContext,
} from 'modules/access/components/OrganisationSwitcher';
import { useNetworkState } from 'react-use';
import { SwitchOrganisation } from 'hooks/useSession';
import OrganisationTree from '../OrganisationTree';
import ErrorMessage from '../ErrorMessage';

export interface OrganisationEdge {
  node: SwitchOrganisation;
}
export interface AllOrganisations {
  organisations: {
    edges: Array<OrganisationEdge>;
  };
}

export const fragment = gql`
  fragment organisationNode on Organisation {
    id
    parentId
    name
    isAPurchaser
    isInventoryEnabled
    legalEntity
  }
`;

const OrganisationSwitcherList = () => {
  const { isScoped, searchText }: Context = useContext(SwitcherContext);
  const { t } = useTranslation();
  const { online } = useNetworkState();

  const ALL_ORGANISATIONS_LIST = gql`
    query organisationsQuery(
      $pageSize: Int
      $filter: RansackFilterType
      $sort: [RansackSortType!]
    ) {
      organisations(first: $pageSize, filter: $filter, sort: $sort) {
        edges {
          node {
            ...organisationNode
            parent {
              ...organisationNode
            }
            children {
              ...organisationNode
            }
          }
        }
      }
    }
    ${fragment}
  `;

  const [getQuery, { data, loading, error }] = useLazyQuery<AllOrganisations>(
    ALL_ORGANISATIONS_LIST,
    {
      variables: {
        sort: [{ property: 'name', direction: 'ASC' }],
        filter: {
          q: [
            { property: 'searchText', matcher: 'FUZZY', value: searchText },
            { property: 'isScoped', value: isScoped },
          ],
        },
      },
      fetchPolicy: online ? 'cache-and-network' : 'cache-only',
      nextFetchPolicy: 'cache-first',
      context: {
        uri: `${process.env.REACT_APP_NINJA_API_HOST}/access/api/graphql`,
      },
    }
  );

  useEffect(() => {
    getQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <p>{t('common.loading')}</p>;

  const organisations = flatListToTree(data);
  return (
    <div>
      <div className="py-2">
        {(!data || data?.organisations?.edges?.length === 0) && (
          <div className="message">
            {t('access.organisationSwitcher.emptyOrganisations')}
          </div>
        )}
        <OrganisationTree organisations={organisations} />
      </div>
    </div>
  );
};

export default OrganisationSwitcherList;

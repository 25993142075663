import { Link } from 'react-router-dom';

export interface NavigationItemProps {
  title: string;
  subTitle?: string;
  image?: React.ReactNode;
  path: string;
  key: string;
  adminOnly?: boolean;
}

interface NavigationSidebarItemProp {
  item: NavigationItemProps;
}

export const NavigationSidebarItem: React.FC<NavigationSidebarItemProp> = (
  props
) => {
  const { title, subTitle, image, path } = props.item;
  return (
    <div className="border-b border-gray-100  block py-4">
      <Link className="font-semibold" to={path}>
        <div className="h-full flex flex-col">
          <div className="relative flex items-center h-10">
            <div className="w-4">{image}</div>
            <h5 className="ml-10">{title}</h5>
          </div>
        </div>
      </Link>
      {subTitle && (
        <Link className="block pl-14" to={path}>
          <p>{subTitle}</p>
        </Link>
      )}
    </div>
  );
};

import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RansackFilter } from 'hooks/useRansackFilter';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import { RansackSort } from 'hooks/useRansackSort';
import LoadMore from 'modules/common/components/LoadMore';
import { TRelayPageInfo } from '@apollo/client/utilities/policies/pagination';
import { StockTakesCard } from '../StockTakesCard';
import { StockCountEdge } from '../StockCountList';

export interface StockTakesListProps {
  id: number;
  filter?: RansackFilter;
  sort?: RansackSort;
  online?: boolean;
  lastSyncDate?: string;
  holderId?: number;
}

interface StockTakesData {
  holder: {
    id: number;
    stockLocation: {
      id: number;
      name: string;
      stockTakeStatus: string;
      lastStockTake: {
        id: number;
      };
      stockTakes: StockTakes;
    };
  };
}

export interface StockTakes {
  edges: Array<StockTakesEdge>;
  pageInfo: TRelayPageInfo;
}
export interface StockCountData {
  edges: Array<StockCountEdge>;
  pageInfo: TRelayPageInfo;
}

export interface StockTakesEdge {
  node: StockTakesNode;
  pageInfo: TRelayPageInfo;
  cursor?: string;
  sortBy?: string;
}

export interface StockTakesNode {
  id: number;
  stockLocationId: number;
  periodEndingOn: string;
  creatorId: number;
  closedAt: string;
  closable: boolean;
  partial: boolean;
  blind: boolean;
  stockCounts: StockCountData;
}

export const stockTakeFragment = gql`
  fragment stockTakeNode on StockTake {
    id
    closable
    stockLocationId
    periodEndingOn
    creatorId
    closedAt
    createdAt
    blind
    partial
  }
`;

export const STOCK_TAKES_LISTING_QUERY = gql`
  query stockLocationQuery(
    $id: Int!
    $sort: [RansackSortType!]
    $first: Int!
    $after: String
    $holderId: Int
  ) {
    holder(id: $holderId) {
      id
      stockLocation(id: $id) {
        id
        name
        stockTakeStatus
        lastStockTake {
          id
        }
        stockTakes(sort: $sort, first: $first, after: $after) {
          edges {
            node {
              ...stockTakeNode
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${stockTakeFragment}
`;

const StockTakesList: React.FC<StockTakesListProps> = ({
  id,
  sort,
  online,
  lastSyncDate,
  holderId,
}) => {
  const { t } = useTranslation();

  const [
    getStockTakeList,
    { loading, error, data, fetchMore },
  ] = useLazyQuery<StockTakesData>(STOCK_TAKES_LISTING_QUERY, {
    variables: {
      id,
      first: 25,
      sort: sort && {
        property: sort?.property,
        direction: sort?.direction,
      },
      isAvailableOffline: !!lastSyncDate,
      holderId,
    },
    fetchPolicy: online
      ? lastSyncDate
        ? 'cache-and-network'
        : 'network-only'
      : 'cache-first',
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
  });

  useEffect(() => {
    getStockTakeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <ErrorMessage error={error} retry={fetchMore} />;
  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const { name, stockTakeStatus, ...stockLocation } = data.holder.stockLocation;
  const { stockTakes } = stockLocation;

  return (
    <>
      {stockTakes.edges.map((stockTake: StockTakesEdge) => (
        <StockTakesCard
          node={stockTake.node}
          stockLocationId={id}
          key={stockTake.node.id}
          stockTakeStatus={stockTakeStatus}
        />
      ))}
      {fetchMore && (
        <LoadMore
          fetchMore={() => {
            fetchMore({
              variables: {
                after: stockTakes.pageInfo.endCursor,
                isAvailableOffline: !!lastSyncDate,
              },
            });
          }}
          hasNextPage={stockTakes.pageInfo.hasNextPage}
        />
      )}
    </>
  );
};

export default StockTakesList;

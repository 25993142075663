import { take } from 'lodash';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAuth } from './useAuth';

export interface DraftStockTakes {
  userID: number;
  stockTakes: Array<DraftStockTake>;
}

export interface DraftStockTake {
  stockTakeID: number;
  stockLocationId: number;
  name: string;
  stockCounts: Array<DraftStockCount>;
}

export interface DraftStockCount {
  updatedAt: Date;
  quantity: number;
  stockCountId: number;
  productName: string;
}

export default function useStockCountDraft() {
  const [, setDraftsStockCount] = useLocalStorage<Array<DraftStockTakes>>(
    'draftsStockCount',
    []
  );
  const getDataFromLocalStorage = (key: string) => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : [];
  };
  const draftsStockCount: Array<DraftStockTakes> =
    getDataFromLocalStorage('draftsStockCount') || [];

  const {
    user: { id: userId },
  } = useAuth();

  const userDrafts = draftsStockCount?.find((x) => x.userID === userId);

  const draftStockTakes = userDrafts?.stockTakes;

  const draftStockCountLength = draftStockTakes?.length || 0;

  const getDraftStockTakes = () => {
    return draftStockTakes || [];
  };

  const getDraftStockTake = (stockTakeId: number) => {
    return draftStockTakes?.find((x) => x.stockTakeID === stockTakeId);
  };

  const getTopNStockCountDrafts = (n: number) => {
    return take(draftStockTakes, n);
  };

  const getUpdatedStockCountValue = (
    stockTakeId: number,
    stockCountId: number
  ) => {
    const draftStockTake = getDraftStockTake(stockTakeId);
    return draftStockTake?.stockCounts?.find(
      (item) => item.stockCountId === stockCountId
    )?.['quantity'];
  };

  const saveStockCountDraft = (
    data: DraftStockCount,
    stockTakeId: number,
    stockLocationId: number,
    name: string
  ) => {
    if (userDrafts) {
      //user already have some saved drafts for any stockTake
      const stockTakeData = findStockTakeDrafts(stockTakeId); //Check if any saved stockCount for same StockTake
      if (stockTakeData) {
        //Append to existing stockCounts Array
        addInExistingStockTake(stockTakeData, data);
      } else {
        //New record for different StockTake
        addNewStockTake(data, stockTakeId, stockLocationId, name);
      }
    } else {
      //1st item saved by logged in user
      addNewUserDraft(data, stockTakeId, stockLocationId, name);
    }
    setDraftsStockCount(draftsStockCount); //Update localStorage
  };

  const findStockTakeDrafts = (stockTakeId: number) => {
    return userDrafts?.stockTakes?.find((x) => x.stockTakeID === stockTakeId);
  };

  const addInExistingStockTake = (
    stockTakeData: DraftStockTake,
    data: DraftStockCount
  ) => {
    const stockCountDraftIndex = stockTakeData?.stockCounts.findIndex(
      (x) => x.stockCountId === data?.stockCountId
    );
    if (stockCountDraftIndex > -1) {
      stockTakeData?.stockCounts?.splice(stockCountDraftIndex, 1, data);
    } else stockTakeData?.stockCounts.push(data);
  };

  const addNewStockTake = (
    data: DraftStockCount,
    stockTakeId: number,
    stockLocationId: number,
    name: string
  ) => {
    userDrafts?.stockTakes?.push({
      stockTakeID: stockTakeId,
      stockLocationId: stockLocationId,
      name: name,
      stockCounts: [data],
    });
  };

  const addNewUserDraft = (
    data: DraftStockCount,
    stockTakeId: number,
    stockLocationId: number,
    name: string
  ) => {
    const userData = {
      userID: userId,
      stockTakes: [
        {
          stockTakeID: stockTakeId,
          stockLocationId: stockLocationId,
          name: name,
          stockCounts: [data],
        },
      ],
    };
    draftsStockCount.push(userData);
  };

  const deleteDraftStockTake = (
    stockTakeId: number,
    stockCountIdArray?: Array<number>
  ) => {
    if (!userDrafts) {
      return;
    }
    const stockTakeIndex = userDrafts.stockTakes?.findIndex(
      (x) => x.stockTakeID === stockTakeId
    );

    if (stockCountIdArray && stockTakeIndex > -1) {
      stockCountIdArray?.forEach((stockCountId) => {
        const stockCountIndex = userDrafts?.stockTakes?.[
          stockTakeIndex
        ].stockCounts?.findIndex((x) => x.stockCountId === stockCountId);

        if (stockCountIndex > -1) {
          removeStockCount(stockTakeIndex, stockCountIndex);
        }
      });
    } else if (stockTakeIndex > -1) {
      userDrafts?.stockTakes?.splice(stockTakeIndex, 1);
    }
    if (userDrafts?.stockTakes.length === 0) {
      deleteUserDraft();
    }
    setDraftsStockCount(draftsStockCount);
  };

  const removeStockCount = (
    stockTakeIndex: number,
    stockCountIndex: number
  ) => {
    userDrafts?.stockTakes?.[stockTakeIndex]?.stockCounts?.splice(
      stockCountIndex,
      1
    );
    if (userDrafts?.stockTakes?.[stockTakeIndex]?.stockCounts?.length === 0) {
      userDrafts?.stockTakes?.splice(stockTakeIndex, 1);
    }
  };

  const deleteUserDraft = () => {
    const userDraftIndex = draftsStockCount?.findIndex(
      (x) => x.userID === userId
    );
    if (userDraftIndex > -1) draftsStockCount.splice(userDraftIndex, 1);
    setDraftsStockCount(draftsStockCount);
  };

  return {
    getDraftStockTakes,
    draftStockCountLength,
    getUpdatedStockCountValue,
    getDraftStockTake,
    getTopNStockCountDrafts,
    saveStockCountDraft,
    deleteDraftStockTake,
  };
}

import { useContext } from 'react';
import { ChevronDown, ChevronRight } from 'heroicons-react';
import {
  Context,
  SwitcherContext,
} from 'modules/access/components/OrganisationSwitcher';
import { SwitchOrganisation } from 'hooks/useSession';
import OrganisationLabel from '../OrganisationLabel';
import OrganisationTree from '../OrganisationTree';

interface OrganisationTreeNodeProps {
  depth: number;
  onClick: Function;
  organisation: SwitchOrganisation;
}

const OrganisationTreeNode: React.FC<OrganisationTreeNodeProps> = ({
  depth,
  onClick,
  organisation,
}) => {
  const { onNodeToggle, toggledNodes }: Context = useContext(SwitcherContext);

  const { id, children = [] } = organisation;

  const isToggled = toggledNodes && !!toggledNodes[id];
  const hasChildren = children.length > 0;
  const showChildren = hasChildren && isToggled;
  let margin = depth * 2 + 2;

  const Icon = !showChildren ? <ChevronRight /> : <ChevronDown />;

  return (
    <>
      <div
        className={`compact flex items-center cursor-pointer ${
          !hasChildren ? 'pl-8' : ''
        }`}
        onClick={() => onClick(id)}
      >
        {hasChildren && onNodeToggle && (
          <div
            className="mr-2"
            onClick={(e) => onNodeToggle(e, organisation.id)}
          >
            {Icon}
          </div>
        )}
        <OrganisationLabel depth={depth} organisation={organisation} />
      </div>
      {showChildren && (
        <div className={`ml-${margin}`}>
          <OrganisationTree depth={depth + 1} organisations={children} />
        </div>
      )}
      {!showChildren && <div className="border-b-2 border-gray-200 ml-8"></div>}
    </>
  );
};

export default OrganisationTreeNode;

import { StatusOffline } from 'heroicons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from '../Button';
interface OfflineFetchMessageProps {
  fetchMore: Function | undefined;
  online: boolean | undefined;
}

export const OfflineFetchMessage: React.FC<OfflineFetchMessageProps> = ({
  fetchMore,
  online,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleOffline = () => {
    if (!online) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        toast(t('common.canNotLoad'), {
          type: 'error',
          position: 'bottom-center',
          autoClose: 2000,
        });
      }, 1500);
    } else {
      fetchMore && fetchMore();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex md:px-10 px-4">
        <div>
          <StatusOffline className="fill-current text-blue w-10 h-10" />
        </div>
        <div className="flex flex-col md:ml-10 ml-5">
          <p className="text-2xl font-semibold text-black-700">
            {t('common.noInternet')}
          </p>
          <p>{t('common.fetchLater')}</p>
        </div>
      </div>
      <div className="flex justify-end items-end place-content-between my-2 md:pr-10">
        <Button
          variant="rounded"
          color="primary"
          loading={loading}
          handleClick={() => handleOffline()}
        >
          <p>{t('common.retry')}</p>
        </Button>
      </div>
    </div>
  );
};

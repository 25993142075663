import { SyncDateType } from 'hooks/useOfflineSync';
import useLocalStorage from './useLocalStorage';

export default function useLastSyncDate(
  stockLocationId: number,
  stockTakeId?: number
) {
  const [stockTakeSyncDate] = useLocalStorage<SyncDateType>(
    'stockTakeSyncDate',
    {}
  );

  const [stockCountsSyncDate] = useLocalStorage<SyncDateType>(
    'stockCountsSyncDate',
    {}
  );

  const getStockLocationLastSyncDate = (stockLocationId: number) => {
    if (stockTakeSyncDate?.[stockLocationId]) {
      return stockTakeSyncDate[stockLocationId];
    }
  };

  const getStockTakeLastSyncDate = (stockTakeId: number) => {
    if (stockCountsSyncDate?.[stockTakeId]) {
      return stockCountsSyncDate[stockTakeId];
    }
  };

  const getLastSyncDate = (): string | undefined => {
    const lastStockLocationSyncDate = getStockLocationLastSyncDate(
      stockLocationId
    );
    const lastStockTakeSyncDate =
      stockTakeId && getStockTakeLastSyncDate(stockTakeId);
    return lastStockTakeSyncDate || lastStockLocationSyncDate;
  };
  return { getLastSyncDate };
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

export interface CardProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  to?: string;
  color?: string;
}

export const Card: React.FC<CardProps> = ({ children, to, color }) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) history.push(to);
  };

  const cardStyle = classnames([
    'rounded-md shadow-lg px-2 md:px-6 py-6 mb-6',
    color,
    { 'cursor-pointer': to },
  ]);

  return (
    <div onClick={to ? handleClick : undefined} className={cardStyle}>
      {children}
    </div>
  );
};

export default Card;

export const DescriptionListItem: React.FC<{
  label: string;
  children: React.ReactNode;
}> = ({ label, children }) => {
  return (
    <div className="border-b-2 px-4 py-5 grid grid-cols-3 gap-4 px-4 md:grid-cols-3 items-center">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="text-sm text-gray-900 col-span-2 flex justify-end md:justify-start">
        {children}
      </dd>
    </div>
  );
};

export const DescriptionList: React.FC = ({ children }) => {
  return (
    <div className="bg-white overflow-hidden sm:rounded-lg">
      <div className="border-gray-200">
        <dl>{children}</dl>
      </div>
    </div>
  );
};

import classNames from 'classnames';

export const useStockTakeColor = (present: boolean, closable: boolean) => {
  const cardColor = classNames(
    {
      'bg-red-100': !closable && present,
    },
    {
      'bg-green-100': (closable && present) || !present,
    }
  );
  return cardColor;
};

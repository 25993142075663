import React, { useEffect, useState } from 'react';
import {
  createHttpLink,
  ApolloClient,
  from,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { InMemoryCache, makeVar } from '@apollo/client/core';
import { relayStylePagination } from '@apollo/client/utilities';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { addAuthHeadersLink, storeAuthHeadersLink } from 'apollo/headerLinks';
import { WrappedSort } from 'hooks/usePreservedSort';
import { WrappedFilter } from 'hooks/usePreservedFilter';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { useTranslation } from 'react-i18next';
import useCacheStoreData from 'hooks/useCacheStoreData';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
interface ApolloProviderWrapperProps {
  children: React.ReactNode;
}

export const preservedSorts = makeVar<WrappedSort[]>([]);
export const preservedFilters = makeVar<WrappedFilter[]>([]);

const ApolloProviderWrapper: React.FC<ApolloProviderWrapperProps> = ({
  children,
}) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();
  const { merge: cacheMerge, read: cacheRead } = useCacheStoreData();
  const { t } = useTranslation();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
  });

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors && graphQLErrors?.length > 0) {
      graphQLErrors?.forEach((error) => {
        if (error?.extensions?.code === 401)
          toast(`${error?.message}`, { type: 'error' });
        else
          toast(t('common.somethingWentWrong'), {
            type: 'error',
            toastId: 'somethingWentWrong',
          });
        Sentry.captureMessage(error.message, Sentry.Severity.Error);
      });
    }
    if (networkError) {
      Sentry.captureException(networkError);
    }
    // Optionally, set response.errors to null to ignore the captured errors
    // at the component level. Omit this if you still want component-specific handling
    //response.errors = null
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      jitter: false,
    },
    attempts: {
      max: 8,
      retryIf: (error, operation) =>
        !(
          (
            !error.message ||
            error.message !== 'Failed to fetch' ||
            operation.operationName !== 'UpdateStockCountMutation'
          ) // Necessary to prevent multiple calls for stocklocation listing page etc, which have only select queries (no mutation)
        ),
      // TODO: revisit once we have the proper error message
    },
  });

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache({
        typePolicies: {
          Holder: {
            fields: {
              stockLocations: {
                merge: cacheMerge,
                read: cacheRead,
              },
            },
          },
          StockLocation: {
            fields: {
              stockTakes: {
                ...relayStylePagination(),
                read: cacheRead,
              },
            },
          },
          StockTake: {
            fields: {
              stockCounts: {
                merge: cacheMerge,
                read: cacheRead,
              },
              stockCount(_, { args, toReference }) {
                return toReference({
                  __typename: 'StockCount',
                  id: args?.id,
                });
              },
            },
          },
        },
      });

      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
      });

      await newPersistor.restore();
      setPersistor(newPersistor);

      setClient(
        new ApolloClient({
          link: from([
            addAuthHeadersLink,
            storeAuthHeadersLink,
            errorLink,
            httpLink,
            retryLink,
          ]),
          defaultOptions: {
            watchQuery: {
              errorPolicy: 'all',
            },
          },
          uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
          headers: {
            Accept: 'application/vnd.mbapi.v2+json',
          },
          cache,
        })
      );
    }

    init().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const clearCache = useCallback(() => {
  //   if (!persistor) {
  //     return;
  //   }
  //   persistor.purge();
  // }, [persistor]);

  if (!client) {
    return <h2>{t('common.initialiseApp')}</h2>;
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;

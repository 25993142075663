import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import Routes from 'routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'hooks/useAuth';
import { SessionProvider } from 'hooks/useSession';
import ApolloProviderWrapper from 'apollo/ApolloProviderWrapper';
import en from 'locales/en.json';
import 'react-toastify/dist/ReactToastify.css';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  lng: 'en',
});

function App() {
  const { t } = useTranslation();
  const handleUpdateServiceWorker = () => {
    navigator.serviceWorker.getRegistrations().then((regs) =>
      regs.forEach((reg) => {
        reg && reg.waiting && reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      })
    );
  };

  return (
    <div className="h-screen py-5 container mx-auto">
      <AuthProvider>
        <HelmetProvider>
          <ApolloProviderWrapper>
            <SessionProvider>
              <Router>
                <Routes />
              </Router>
            </SessionProvider>
          </ApolloProviderWrapper>
        </HelmetProvider>
      </AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        draggable
        hideProgressBar={true}
      />
      <div
        id="new-updates"
        className="hidden fixed object-bottom p-3 bg-gray-500 text-white bottom-3"
      >
        {t('common.serviceWorker.updateNotification.message')}{' '}
        <button onClick={handleUpdateServiceWorker}>
          {t('common.serviceWorker.updateNotification.confirm')}
        </button>
      </div>
    </div>
  );
}

export default App;

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { preservedFilters, preservedSorts } from 'apollo/ApolloProviderWrapper';
import { useSession } from 'hooks/useSession';
import useRansackFilter from 'hooks/useRansackFilter';
import DefaultLayout from 'modules/common/components/DefaultLayout';
import StockLocationsList from 'modules/inventory/components/StockLocationsList';
import SearchInput from 'modules/common/components/SearchInput';
import useSort, { sortProperties } from 'hooks/useRansackSort';
import { usePreservedFilter } from 'hooks/usePreservedFilter';
import { usePreservedSort } from 'hooks/usePreservedSort';

export default function StockLocations() {
  const { organisation, holder } = useSession();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useForm();

  const { debouncedQuery, filter } = useRansackFilter(500);

  const sortOptions = sortProperties(['name']);

  const handleChange = (e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;

    debouncedQuery([
      {
        property: 'name',
        matcher: 'CONT',
        value: target.value,
      },
    ]);
  };

  const { handleSortingChange, activeSort } = usePreservedSort(preservedSorts, {
    id: [organisation.id],
  });

  const {
    ransackSort = activeSort || sortOptions[0],
    SortMenu,
    sortByScreenVisibile,
  } = useSort();

  const {
    handleFilterChange,
    activeFilter,
  } = usePreservedFilter(preservedFilters, { id: [organisation.id] });

  if (filter) {
    handleFilterChange(filter);
  }

  return (
    <>
      <Helmet>
        <title>
          {t('inventory.stockLocations.pageTitle')} | {t('common.siteTitle')}
        </title>
      </Helmet>

      <DefaultLayout
        title={organisation.name}
        parentPagePath={`/`}
        disableScroll={sortByScreenVisibile}
      >
        <div>
          <SearchInput
            name="stockLocationSearch"
            register={register}
            errors={errors.search}
            onChange={handleChange}
            defaultValue={activeFilter()?.q?.[0].value}
            placeholder={t('inventory.stockLevelSearch.searchPlaceholder')}
          />
          <div className="flex justify-between mb-2 items-center">
            <p className="font-bold text-xl text-gray-700">
              {t('inventory.stockLocations.pageTitle')}
            </p>
            <SortMenu
              sortOptions={sortOptions}
              defaultSorting={ransackSort}
              onSortingChange={handleSortingChange}
            />
          </div>
        </div>
        {holder?.id && (
          <StockLocationsList
            loadMore={true}
            sort={ransackSort}
            filter={activeFilter()}
            holderId={holder?.id}
          />
        )}
      </DefaultLayout>
    </>
  );
}

import { SwitchOrganisation } from 'hooks/useSession';
import { OfficeBuilding } from 'heroicons-react';
import { ReactComponent as BedIcon } from 'assets/img/bed.svg';
import { ReactComponent as Industry } from 'assets/img/industry.svg';

export interface OrganisationLabelProps {
  depth: number;
  organisation: SwitchOrganisation;
}
const OrganisationLabel: React.FC<OrganisationLabelProps> = ({
  depth,
  organisation,
}) => {
  const { isAPurchaser, legalEntity, name, parent } = organisation;

  const isPurchaserEntity = isAPurchaser && legalEntity;
  const isDepartment = isAPurchaser && !legalEntity;
  const isSupplier = !isAPurchaser;

  const iconStyles = 'w-5 h-5 mr-2';

  const getIcon = () => {
    switch (true) {
      case isDepartment:
        return <BedIcon className={iconStyles} />;
      case isPurchaserEntity:
        return <OfficeBuilding className={iconStyles} />;
      case isSupplier:
        return <Industry className={iconStyles} />;
    }
  };

  return (
    <span className="flex py-2 items-center">
      <div className="flex">{getIcon()}</div>
      <div>
        <span title={name}>{name}</span>
        {depth === 1 && parent && (
          <span className="grey ml-2">{`(${parent.name})`}</span>
        )}
      </div>
    </span>
  );
};

export default OrganisationLabel;

import { gql, useLazyQuery, useApolloClient } from '@apollo/client';
import { TRelayPageInfo } from '@apollo/client/utilities/policies/pagination';
import { preservedSorts } from 'apollo/ApolloProviderWrapper';
import {
  StockCountEdge,
  StockCountFragment,
} from 'modules/inventory/components/StockCountList';
import {
  LastStockTakeData,
  StockLocation,
} from 'modules/inventory/components/StockLocationsList';
import {
  stockTakeFragment,
  StockTakesEdge,
  StockTakesNode,
} from 'modules/inventory/components/StockTakesList';
import { useState } from 'react';
import useLocalStorage from './useLocalStorage';
import { usePreservedSort } from './usePreservedSort';
import { useSession } from './useSession';

export type OfflineSyncStatus = 'loading' | 'success' | 'error';

interface OfflineLastStocktake extends LastStockTakeData {
  partial: boolean;
  blind: boolean;
}
interface OfflineStockLocation extends StockLocation {
  departments: {
    node: {
      id: number;
      name: string;
    };
  };
  lastStockTake: OfflineLastStocktake;
  stockTakes: {
    edges: Array<StockTakesEdge>;
  };
}
interface OfflineData {
  holder: {
    id: number;
    stockLocation: OfflineStockLocation;
  };
}

export interface SyncDateType {
  [key: string]: string;
}

export interface StockTakeData {
  holder: {
    stockLocation: {
      stockTake: {
        stockCounts: {
          edges: Array<StockCountEdge>;
          pageInfo: TRelayPageInfo;
        };
      };
    };
  };
}

export const OFFLINE_STOCK_LOCATION_QUERY = gql`
  query offlineStockLocation(
    $id: Int!
    $sort: [RansackSortType!]
    $holderId: Int
  ) {
    holder(id: $holderId) {
      id
      stockLocation(id: $id) {
        id
        name
        customSort
        departments {
          nodes {
            id
            name
          }
        }
        balanceValue
        lastStockTake {
          id
          periodEndingOn
          partial
          blind
        }
        stockTakeStatus
        stockTakes(sort: $sort) {
          edges {
            node {
              ...stockTakeNode
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${stockTakeFragment}
`;

export const OFFLINE_STOCK_TAKE_QUERY = gql`
  query offlineStockTakeQuery(
    $stockLocationId: Int!
    $id: Int!
    $first: Int
    $after: String
    $holderId: Int
    $barcodeBody: String!
  ) {
    holder(id: $holderId) {
      id
      location(id: $stockLocationId) {
        id
        barcode(body: $barcodeBody) {
          id
          stockCount(stockTakeId: $id){
            id
          }
        }
      }
      stockLocation(id: $stockLocationId) {
        customSort
        name
        id
        stockTake(id: $id) {
          ...stockTakeNode
          stockCounts(first: $first, after: $after) {
            edges {
              cursor
              node {
                ...stockCountNode
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
  ${StockCountFragment},${stockTakeFragment}`;

export function useFetchData(id: number) {
  const client = useApolloClient();
  const [syncStatus, setSyncStatus] = useState<OfflineSyncStatus>();
  const { activeSort } = usePreservedSort(preservedSorts, {
    stockLocationId: id.toString(),
  });
  const [stockTakeSyncDate, setStockTakeSyncDate] = useLocalStorage(
    'stockTakeSyncDate',
    {}
  );
  const { holder } = useSession();
  const lastSynced: SyncDateType = stockTakeSyncDate;
  const fetchMoreStockTakes = async (
    stockTakeId: number,
    edgesCollection?: Array<StockTakesEdge>,
    afterCursor?: string
  ) => {
    const response = await client.query({
      query: OFFLINE_STOCK_TAKE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 25,
        after: afterCursor,
        stockLocationId: id,
        id: stockTakeId,
        holderId: holder?.id,
        barcodeBody: '',
      },
    });
    edgesCollection = {
      ...edgesCollection,
      ...response.data.holder.stockLocation.stockTake.stockCounts.edges,
    };

    const {
      hasNextPage,
      endCursor,
    } = response.data.holder.stockLocation.stockTake.stockCounts.pageInfo;
    if (hasNextPage) {
      await fetchMoreStockTakes(stockTakeId, edgesCollection, endCursor);
    } else {
      return edgesCollection;
    }
  };

  /* Assumption that a particular StockLocation will not have more than 25 StockTakes 
   (as by default we get 25 records from offlineSync queries in one execution). */
  const fetchAllStockTake = (data: OfflineData) => {
    Promise.all(
      data?.holder?.stockLocation?.stockTakes?.edges?.map(
        async (edge: { node: StockTakesNode }) => {
          if (!edge.node.closedAt) {
            const stockTakeId = edge.node.id;
            await fetchMoreStockTakes(stockTakeId, []);
          }
        }
      )
    )
      .then(() => {
        Object.assign(lastSynced, { [id]: new Date().toISOString() });
        setStockTakeSyncDate(lastSynced);
        setSyncStatus('success');
      })
      .catch((error) => {
        setSyncStatus('error');
      });
  };

  const [getData] = useLazyQuery(OFFLINE_STOCK_LOCATION_QUERY, {
    variables: {
      id: id,
      first: 25,
      sort: {
        property: activeSort?.property,
        direction: activeSort?.direction,
      },
      holderId: holder?.id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: fetchAllStockTake,
    onError: () => {
      setSyncStatus('error');
    },
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
  });

  function fetch(id: number) {
    setSyncStatus('loading');
    getData();
  }

  return {
    fetch,
    syncStatus,
    lastSynced,
  };
}

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast, TypeOptions } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';
import { useForm } from 'react-hook-form';
import { preservedSorts, preservedFilters } from 'apollo/ApolloProviderWrapper';
import { CameraOutline } from 'heroicons-react';
import DefaultLayout from 'modules/common/components/DefaultLayout';
import useSort, { sortProperties } from 'hooks/useRansackSort';
import useRansackFilter, { RansackFilter } from 'hooks/useRansackFilter';
import { usePreservedSort } from 'hooks/usePreservedSort';
import { usePreservedFilter } from 'hooks/usePreservedFilter';
import useLastSyncDate from 'hooks/useLastSyncDate';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import SearchInput from 'modules/common/components/SearchInput';
import StockCountList from 'modules/inventory/components/StockCountList';
import { useSession } from 'hooks/useSession';
import useHoneywellFocus from 'hooks/useHoneywellFocus';
import useStockCountDraft from 'hooks/useStockCountDraft';
import SyncError from 'modules/common/components/SyncError';
import Button from 'modules/common/components/Button';
import { StockTakeType } from 'modules/inventory/components/StockCountList';
import { UPDATE_STOCK_COUNT_MUTATION } from '../../components/StockCount';

export interface StockTakeFilter {
  stockLocationId: number;
  stockTakeId: number;
  filter: RansackFilter;
}

export const STOCK_TAKE_QUERY = gql`
  query StockTakeQuery($stockLocationId: Int!, $id: Int!, $holderId: Int) {
    holder(id: $holderId) {
      id
      stockLocation(id: $stockLocationId) {
        name
        id
        customSort
        stockTake(id: $id) {
          id
          periodEndingOn
          blind
          partial
        }
      }
    }
  }
`;

export const StockTake: React.FC = () => {
  const { id, stockLocationId, stockCountId } = useParams<{
    id: string;
    stockLocationId: string;
    stockCountId?: string;
  }>();
  const { t } = useTranslation();
  const { debouncedQuery, filter } = useRansackFilter(500);
  const history = useHistory();
  const { online } = useNetworkState();
  const { holder } = useSession();
  const {
    getDraftStockTake,
    getUpdatedStockCountValue,
    deleteDraftStockTake,
  } = useStockCountDraft();
  const draftStockTake = getDraftStockTake(Number(id));
  const [syncCompleted, setSyncCompleted] = useState(false);
  const { getLastSyncDate } = useLastSyncDate(
    Number(stockLocationId),
    Number(id)
  );

  const {
    register,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm();

  useHoneywellFocus(setFocus);

  const [
    getStockTake,
    { loading, error, data, refetch },
  ] = useLazyQuery<StockTakeType>(STOCK_TAKE_QUERY, {
    variables: {
      stockLocationId: Number(stockLocationId),
      id: Number(id),
      holderId: holder.id,
    },
    context: {
      uri: `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`,
    },
    fetchPolicy: online
      ? getLastSyncDate()
        ? 'cache-and-network'
        : 'network-only'
      : 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (holder?.id) {
      getStockTake();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holder?.id]);

  useEffect(() => {
    setSyncCompleted(false);
  }, [stockCountId]);

  const handleChange = (e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;

    debouncedQuery([
      {
        property: 'product_searchText',
        matcher: 'CONT',
        value: target.value,
      },
    ]);
  };

  //#region filter
  const {
    handleFilterChange,
    activeFilter,
  } = usePreservedFilter(preservedFilters, { stockLocationId, id });

  if (filter) {
    handleFilterChange(filter);
  }
  //#endregion

  //#region  sorting
  const sortOption = sortProperties(['product_concatenatedDescription']);
  const sortOptionPosition = sortProperties(['stockLevel_position']);

  const { handleSortingChange, activeSort } = usePreservedSort(preservedSorts, {
    stockLocationId,
    id,
  });

  const {
    ransackSort = activeSort || sortOptionPosition[0],
    SortMenu,
    sortByScreenVisibile,
  } = useSort();
  //#endregion

  const syncAllDraftStockTake = async () => {
    if (!draftStockTake) return;
    const successStockCounts: Array<number> = [];
    const responses = draftStockTake.stockCounts?.map((item) => {
      return updateQuantity({
        variables: {
          id: item.stockCountId,
          quantity: item.quantity,
        },
      });
    });
    const result = await Promise.all(responses);
    result?.forEach((x) => {
      if (x?.data) {
        successStockCounts?.push(x?.data?.updateStockCount?.stockCount?.id);
      }
    });
    if (successStockCounts?.length > 0) {
      handleSuccess();
      deleteDraftStockTake(Number(id), successStockCounts);
    }
    setSyncCompleted(true);
  };

  const notify = (message: string, toastType: TypeOptions) =>
    toast(message, { type: toastType, toastId: 'stockCountToast' });

  const handleSuccess = () => {
    notify(t('common.successMessage'), 'info');
  };

  const [updateQuantity, { loading: mutationLoading }] = useMutation(
    UPDATE_STOCK_COUNT_MUTATION,
    {
      onCompleted: handleSuccess,
      onError: () => {},
    }
  );

  if (error || !data)
    return (
      <DefaultLayout
        title={t('inventory.stockTake.pageTitle')}
        parentPagePath={`/inventory/stock_locations/${stockLocationId}`}
      >
        {error && <ErrorMessage error={error} retry={refetch} />}
      </DefaultLayout>
    );

  if ((loading && !data) || !data) return <p>{t('common.loading')}</p>;

  const {
    stockLocation,
    stockLocation: {
      customSort,
      name,
      stockTake: { periodEndingOn, blind, partial },
    },
  } = data.holder;

  const sortingOptions = [
    ...(customSort ? sortOptionPosition : []),
    ...sortOption,
  ];

  const month = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    year: 'numeric',
  }).format(new Date(periodEndingOn));

  const subTitle = `${name}: ${month}`;

  return (
    <>
      <Helmet>
        <title>
          {t('inventory.stockTake.pageTitle')} | {t('common.siteTitle')}
        </title>
      </Helmet>
      <DefaultLayout
        title={t('inventory.stockTake.pageTitle')}
        subTitle={subTitle}
        disableScroll={sortByScreenVisibile}
        parentPagePath={`/inventory/stock_locations/${stockLocationId}`}
      >
        <SearchInput
          name="stockLevelSearch"
          register={register}
          errors={errors.search}
          onChange={handleChange}
          defaultValue={activeFilter()?.q?.[0].value}
          placeholder={t('inventory.stockTake.searchPlaceHolder')}
          secondaryIconProps={{
            secondaryIcon: <CameraOutline />,
            secondaryAction: () =>
              history.push(
                `/inventory/stock_locations/${stockLocationId}/stock_takes/${id}/scan_barcode`
              ),
          }}
          inputFieldStyle="mb-5"
        />
        <div className="flex mb-5 justify-between items-center">
          <div>
            {blind && (
              <span className="px-3 py-1 bg-blue rounded-full text-white">
                {t('inventory.stockLocation.blind')}
              </span>
            )}
            {partial && (
              <span className="px-3 py-1 ml-3 bg-blue rounded-full text-white">
                {t('inventory.stockLocation.partial')}
              </span>
            )}
          </div>
          {draftStockTake && (
            <Button
              color="primary"
              variant="rounded"
              disabled={!online}
              handleClick={syncAllDraftStockTake}
              loading={mutationLoading}
            >
              {t('inventory.drafts.syncAll')}
            </Button>
          )}
        </div>
        {syncCompleted && draftStockTake && (
          <SyncError stockTakesDraft={[draftStockTake]} />
        )}
        <div className="flex justify-between mb-2 items-center">
          <p className="font-bold text-xl text-gray-700">
            {t('inventory.stockTake.stockCount')}
          </p>
          {stockLocation && (
            <SortMenu
              sortOptions={sortingOptions}
              defaultSorting={
                activeSort || (customSort ? sortOptionPosition[0] : undefined)
              }
              onSortingChange={handleSortingChange}
            />
          )}
        </div>
        {holder?.id && (
          <StockCountList
            stockLocationId={Number(stockLocationId)}
            id={Number(id)}
            filter={activeFilter()}
            sort={ransackSort}
            online={online}
            getUpdatedStockCountValue={getUpdatedStockCountValue}
            lastSyncDate={getLastSyncDate()}
            holderId={holder?.id}
            stockCountId={stockCountId ? parseInt(stockCountId) : undefined}
            syncCompleted={syncCompleted}
            resetList={() => {
              setValue('stockLevelSearch', '');
            }}
          />
        )}
      </DefaultLayout>
    </>
  );
};

export default StockTake;

import { DraftStockTake } from 'hooks/useStockCountDraft';
import { DraftStockCountCard } from '../DraftStockCountCard';

export interface DraftStockCountListProps {
  drafts?: Array<DraftStockTake>;
}

const DraftStockCountList: React.FC<DraftStockCountListProps> = ({
  drafts,
}) => {
  return (
    <ul>
      {drafts?.map((draft) => {
        return (
          <DraftStockCountCard key={draft.stockTakeID} stockTakeDraft={draft} />
        );
      })}
    </ul>
  );
};

export default DraftStockCountList;

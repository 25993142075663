import { clone, unionBy, uniqBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import { SwitchOrganisation } from 'hooks/useSession';
import {
  AllOrganisations,
  OrganisationEdge,
} from 'modules/common/components/OrganisationSwitcherList';

interface ChildrenOf {
  [key: string]: Array<SwitchOrganisation>;
}

export function flatListToTree(data: AllOrganisations | undefined) {
  const deduped = uniqBy(data?.organisations?.edges, 'node.id');
  const dataMap = keyBy(deduped, 'node.id');

  const childrenOf: ChildrenOf = {};
  let tree: Array<SwitchOrganisation> = [];

  deduped.forEach((object: OrganisationEdge) => {
    const organisation = clone(object.node);
    const { parentId, id, children = [] } = organisation;

    childrenOf[id] = childrenOf[id] || clone(children);
    organisation.children = childrenOf[id];

    if (parentId && dataMap[parentId]) {
      childrenOf[parentId] = childrenOf[parentId] || [];
      childrenOf[parentId] = unionBy(
        childrenOf[parentId],
        [organisation],
        'id'
      );
    } else {
      tree = unionBy(tree, [organisation], 'id');
    }
  });

  return tree;
}

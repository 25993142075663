import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import OrganisationSwitcherHeader from 'modules/common/components/OrganisationSwitcherHeader';
import OrganisationSwitcherList from 'modules/common/components/OrganisationSwitcherList';
import DefaultLayout from 'modules/common/components/DefaultLayout';

export interface Context {
  isScoped?: boolean;
  onNodeToggle?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => void;
  searchText?: string;
  toggleIsScoped?: Dispatch<SetStateAction<boolean>>;
  toggledNodes?: { [key: number]: boolean };
  updateSearchText?: Function;
}
//TODO: Remove SwitcherContext and use react-hook-form
export const SwitcherContext = createContext({});

function OrganisationSwitcher() {
  const { t } = useTranslation();
  const [searchText, updateSearchText] = useState('');
  const [isScoped, toggleIsScoped] = useState(true);
  const [toggledNodes, setToggledNodes] = useState<{ [key: number]: boolean }>(
    {}
  );

  const onNodeToggle = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => {
    e.stopPropagation();
    let temp = toggledNodes;
    temp = { ...temp, [id]: !temp[id] };
    setToggledNodes(temp);
  };

  const context: Context = {
    isScoped,
    onNodeToggle,
    searchText,
    toggleIsScoped,
    toggledNodes,
    updateSearchText,
  };

  return (
    <SwitcherContext.Provider value={context}>
      <DefaultLayout
        title={t('access.organisationSwitcher.switchOrganisation')}
        parentPagePath={`/`}
      >
        <OrganisationSwitcherHeader />
        <OrganisationSwitcherList />
      </DefaultLayout>
    </SwitcherContext.Provider>
  );
}

export default OrganisationSwitcher;

import { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      // check for sw updates on page change
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, [history]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user.id ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;

import { useState } from 'react';
import SortMenuComponent from 'modules/common/components/SortMenu';

type RansackSortDirectionType = 'ASC' | 'DESC';
export interface RansackSort {
  property: string;
  direction: RansackSortDirectionType;
}

export interface SortProperty extends RansackSort {
  label: string;
  title: string;
  key: string;
}

export default function useSort() {
  const [sortByScreenVisibile, setSortByScreenVisibile] = useState(false);
  const [ransackSort, setRansackSort] = useState<RansackSort>();

  interface SortMenuOptions {
    sortOptions: Array<SortProperty>;
    defaultSorting?: RansackSort | undefined;
    onSortingChange?: Function;
  }

  const SortMenu: React.FC<SortMenuOptions> = ({
    sortOptions,
    defaultSorting,
    onSortingChange,
  }) => {
    return (
      <SortMenuComponent
        activeRansackSort={ransackSort || defaultSorting}
        setRansackSort={setRansackSort}
        setSortByScreenVisibile={setSortByScreenVisibile}
        sortByScreenVisibile={sortByScreenVisibile}
        sortOptions={sortOptions}
        onSortingChange={onSortingChange}
      />
    );
  };

  return {
    ransackSort,
    SortMenu,
    sortByScreenVisibile,
  };
}

const directions: Array<RansackSortDirectionType> = ['ASC', 'DESC'];

function sortPropertyDirections(property: string): Array<SortProperty> {
  return directions.map((direction) => {
    return sortProperty({ property, direction });
  });
}

export const sortProperty = ({ property, direction }: RansackSort) => ({
  title: `common.sortOptions.title.${property}.${direction}`,
  property,
  key: `${property}${direction}`,
  label: `common.sortOptions.title.${property}.label`,
  direction: direction,
});

export const sortProperties = (sortProperties: Array<string>) => {
  const sortOptions: Array<SortProperty> = [];
  sortProperties.forEach((property) => {
    sortOptions.push(...sortPropertyDirections(property));
  });
  return sortOptions;
};

import useMoney from 'hooks/useMoney';

interface MoneyValueCardProps {
  moneyValue: number;
}

export const MoneyValueCard: React.FC<MoneyValueCardProps> = ({
  moneyValue,
}) => {
  const { format: money } = useMoney();
  const value = money(moneyValue);
  return (
    <dl className="mt-1 text-sm flex">
      <dt className="font-medium text-gray-500 mr-2">Balance</dt>
      <dd className="text-gray-900">{value}</dd>
    </dl>
  );
};

import React, { useEffect, useState } from 'react';
import useBarcode from 'hooks/useBarcode';
import { toast } from 'react-toastify';
import {
  ChevronLeft,
  LightningBolt,
  LightningBoltOutline,
} from 'heroicons-react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

export type BarcodeStatus = 'detecting' | 'searching' | 'found';

export type BarcodeReaderProps = {
  barcodeStatus: BarcodeStatus;
  onCodeRead: (text: string) => void;
  vibrate?: boolean;
  handleBack: () => void;
};

export const BarcodeReader: React.FC<BarcodeReaderProps> = ({
  onCodeRead,
  barcodeStatus,
  vibrate = true,
  handleBack,
}) => {
  const {
    videoElement,
    toggleTorch,
    isTorchEnabled,
    isTorchAvailable,
  } = useBarcode(onCodeRead, vibrate);
  const [timeoutId, setTimeoutId] = useState<number>();
  const { t } = useTranslation();
  const customId = 'barcodeNotFoundToast';

  useEffect(() => {
    let _timeoutId = -1;
    if (barcodeStatus === 'detecting') {
      videoElement.current.play();
      _timeoutId = window.setTimeout(() => {
        toast(t('inventory.scanBarcode.barcodeNotDetected'), {
          toastId: customId,
          type: 'warning',
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }, 30000);
      setTimeoutId(_timeoutId);
    } else {
      window.clearInterval(timeoutId);
    }
    return () => {
      window.clearInterval(_timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeStatus]);

  return (
    <>
      <video
        ref={videoElement}
        muted
        className="fixed min-w-full min-h-full bg-black bottom-0 right-0 "
        autoPlay={false}
      />
      <div className="absolute top-20 z-20">
        <Button handleClick={() => handleBack()}>
          <ChevronLeft />
        </Button>
      </div>
      {isTorchAvailable && ( //Hide button if flash not available (eg. iPod, iPad)
        <div className="absolute top-20 right-0 z-20">
          <Button handleClick={() => toggleTorch()}>
            {isTorchEnabled ? <LightningBolt /> : <LightningBoltOutline />}
          </Button>
        </div>
      )}
      <div className="w-screen absolute bottom-0 left-0 h-24 items-center flex justify-center bg-blue-dark">
        <p className="text-white font-bold">
          {barcodeStatus === 'searching'
            ? t('inventory.scanBarcode.barcodeDetected')
            : t('inventory.scanBarcode.scanBarcode')}
        </p>
      </div>
    </>
  );
};

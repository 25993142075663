// @ts-nocheck
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useNetworkState } from 'react-use';
import Input from 'modules/common/components/Input';
import { useAuth, Credentials } from 'hooks/useAuth';
import { Button } from 'modules/common/components/Button';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useSession } from 'hooks/useSession';

export const LoginForm: React.FC = ({ useAuthHook = useAuth }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [signInError, setError] = useState(null);
  const auth = useAuthHook();
  const { online } = useNetworkState();
  const client = useApolloClient();
  const { user } = useAuth();
  const [prevUser] = useLocalStorage('prevUser', '');
  const { deleteSession } = useSession();

  let history = useHistory();
  const { from } = { from: { pathname: '/' } };
  const onSubmit = async (data: Credentials) => {
    try {
      deleteSession();
      await auth.signIn(data);
      setError(null);
      if (user?.id !== parseInt(prevUser))
        client.cache.reset().then(() => history.replace(from));
      else history.replace(from);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col flex-1 justify-center items-stretch"
    >
      <h1 className="text-center text-3xl font-medium mb-3">
        {t('access.login.header')}
      </h1>
      <h2 className="text-center mb-16 text-gray-500">
        {t('access.login.slogan')}
      </h2>
      <div className="mb-10">
        <Input
          required
          register={register}
          name="email"
          type="text"
          label={t('access.login.form.emailAddress.label')}
          errors={errors.email}
          inputFieldStyle="mb-0"
        />
        <Input
          required
          register={register}
          name="password"
          type="password"
          label={t('access.login.form.password.label')}
          errors={errors.password}
          inputFieldStyle="mt-10"
        />
      </div>
      <Button
        disabled={!online}
        htmlType="submit"
        variant="default"
        color={'primary'}
        handleClick={handleSubmit(onSubmit)}
      >
        {t('access.login.form.submit')}
      </Button>
      <div className="flex mt-2 ">
        {t('access.login.form.forgetYour')}
        <Link
          className="text-blue ml-1 lowercase"
          to={{
            pathname: `${process.env.REACT_APP_NINJA_API_HOST}/access/users/password/new?locale=en`,
          }}
          target="_blank"
        >
          {t('access.login.form.password.label')}
        </Link>
        ?
      </div>

      <div className="flex font-blue">
        {t('access.login.form.needMorePurchasePlus')}
        <Link
          className="ml-1 text-blue"
          to={{ pathname: 'https://help.purchaseplus.com/en/' }}
          target="_blank"
        >
          {t('access.login.form.instructions')}
        </Link>
        ?
      </div>

      {!online && (
        <div className="text-sm text-red-600">
          {t('common.offlineSignInMessage')}
        </div>
      )}
      {signInError ? (
        <div className="text-sm text-red-600">{signInError}</div>
      ) : null}
    </form>
  );
};

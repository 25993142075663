import { useEffect, useState, useRef } from 'react';
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  IScannerControls,
} from '@zxing/browser';
import { DecodeHintType } from '@zxing/library';

export default function useBarcode(
  onCodeRead: (text: string) => void,
  vibrate: boolean
) {
  const videoElement = useRef<HTMLVideoElement>(null!);
  const controlRef = useRef<IScannerControls>();
  const [isTorchEnabled, setEnableTorch] = useState<boolean>(false);
  const [isTorchAvailable, setIsTorchAvailable] = useState<boolean>(false);

  useEffect(() => {
    const hints = new Map();
    hints.set(DecodeHintType.TRY_HARDER, true);
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [
      BarcodeFormat.AZTEC,
      BarcodeFormat.CODABAR,
      BarcodeFormat.CODE_39,
      BarcodeFormat.CODE_93,
      BarcodeFormat.CODE_128,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.EAN_8,
      BarcodeFormat.EAN_13,
      BarcodeFormat.ITF,
      BarcodeFormat.MAXICODE,
      BarcodeFormat.PDF_417,
      BarcodeFormat.QR_CODE,
      BarcodeFormat.RSS_14,
      BarcodeFormat.UPC_A,
      BarcodeFormat.UPC_E,
      BarcodeFormat.UPC_EAN_EXTENSION,
      //BarcodeFormat.RSS_EXPANDED
    ]);

    const reader = new BrowserMultiFormatReader();

    reader.setHints(hints);
    reader
      .decodeFromVideoDevice(undefined, videoElement.current, (result) => {
        if (result) {
          videoElement.current?.pause();
          onCodeRead(result.getText());
          if ('vibrate' in navigator && vibrate) {
            navigator.vibrate(500);
          }
        }
      })
      .then((controls) => {
        controlRef.current = controls;
        setIsTorchAvailable(controlRef?.current?.switchTorch !== undefined);
      });

    return () => {
      if (controlRef.current) {
        controlRef.current.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTorch = () => {
    if (controlRef?.current?.switchTorch) {
      controlRef?.current?.switchTorch?.(!isTorchEnabled);
      setEnableTorch(!isTorchEnabled);
    }
  };

  return {
    videoElement,
    toggleTorch,
    isTorchEnabled,
    isTorchAvailable,
  };
}
